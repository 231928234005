import React from 'react';
import { cn } from 'lib/util';
import { Header } from './Header';

type PageLayoutProps = {
  joinVideoVisit?: boolean;
  children: React.ReactNode;
};

export function VideoVisitLayout(props: PageLayoutProps) {
  const { children, joinVideoVisit } = props;

  return (
    <div
      className={
        joinVideoVisit
          ? 'video-visit-join-layout-mobile md:video-visit-join-layout-desktop'
          : 'video-visit-waiting-layout-mobile md:video-visit-waiting-layout-desktop'
      }
    >
      <Header dark />
      <main className="relative p-4 pt-12 text-white video-visit-waiting-layout-mobile-sub md:col-start-2 md:mb-12 md:mt-48 md:p-8 md:drop-shadow">
        {children}
      </main>
    </div>
  );
}

type PageLayoutContentProps = {
  children: React.ReactNode;
};

export function VideoVisitLayoutContent(props: PageLayoutContentProps) {
  const { children } = props;
  return <section className="pb-8 text-white">{children}</section>;
}

type PageLayoutFooterProps = {
  children: React.ReactNode;
};

export function HomePageLayoutFooter(props: PageLayoutFooterProps) {
  const { children } = props;
  const hasExtraContent = React.Children.count(children) > 1;

  return (
    <section
      className={cn(
        'grid items-center pb-8 md:pb-0',
        hasExtraContent
          ? 'grid-cols-[1fr_min-content] '
          : 'grid-cols-[min-content] place-content-end'
      )}
    >
      {children}
    </section>
  );
}
