import React from 'react';
import { cn } from 'lib/util';
import { TaskViewModal } from 'features/pg-homePage/TaskViewModal';
import { useHomePageStore } from 'features/pg-homePage/store';
import { Header } from './Header';

type PageLayoutProps = {
  children: React.ReactNode;
};

export function HomePageLayout(props: PageLayoutProps) {
  const { children } = props;
  const [showTaskModal, labTask, prescriptionTask] = useHomePageStore(state => [
    state.showTaskModal,
    state.labTask,
    state.prescriptionTask,
  ]);

  return (
    <div
      style={{
        backgroundSize: '1144px 1123px',
        backgroundPosition: '52% 50%',
      }}
      className="flex h-screen flex-col overflow-y-auto bg-ivory-2 text-center md:bg-onboarding md:bg-bottom md:bg-no-repeat"
    >
      <Header />
      <main className="h-full rounded-md p-4 pt-12 text-black md:drop-shadow">
        {children}
      </main>
      {showTaskModal && (labTask.length > 0 || prescriptionTask.length > 0) && (
        <TaskViewModal />
      )}
    </div>
  );
}

type PageLayoutContentProps = {
  children: React.ReactNode;
  className?: string;
};

export function HomePageLayoutContent(props: PageLayoutContentProps) {
  const { children, className } = props;
  return (
    <section className={cn('grid grid-cols-1 gap-4 pb-8', className)}>
      {children}
    </section>
  );
}

type PageLayoutFooterProps = {
  children: React.ReactNode;
};

export function HomePageLayoutFooter(props: PageLayoutFooterProps) {
  const { children } = props;
  const hasExtraContent = React.Children.count(children) > 1;

  return (
    <section
      className={cn(
        'grid items-center pb-8 md:pb-0',
        hasExtraContent
          ? 'grid-cols-[1fr_min-content] '
          : 'grid-cols-[min-content] place-content-end'
      )}
    >
      {children}
    </section>
  );
}
