import {
  Heading,
  SubHeading,
  PostVideoVisitLayout,
  PostVideoVisitLayoutContent,
} from 'components';
import { ReactComponent as YourGalileoTeam } from 'assets/your-galileo-team.svg';
import { FC } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PostVideoVisitPage: FC = () => {
  const { t } = useTranslation('visit');
  const navigate = useNavigate();
  return (
    <PostVideoVisitLayout onClose={() => navigate('/')}>
      <PostVideoVisitLayoutContent>
        <div
          data-testid="postVideoVisit"
          className="flex flex-col items-center text-center"
        >
          <Heading>{t('We hope you were satisfied with your care.')}</Heading>

          <div className="my-5 max-w-md items-center text-center">
            <p>
              {t(
                "The medical provider is working on your care plan. We'll send you a text when it's ready."
              )}
            </p>
          </div>

          <SubHeading>{t('Sincerely,')}</SubHeading>
          <div className="mx-auto w-52 max-w-md sm:flex sm:flex-col sm:items-center sm:justify-center">
            <YourGalileoTeam className="mx-auto block h-20 rounded-full sm:mx-0 sm:shrink-0" />
          </div>
          <div className="mx-auto w-52 max-w-md sm:flex sm:flex-col sm:items-center sm:justify-center sm:space-y-0">
            <Button
              className="rounded-full bg-charcoal-2"
              onClick={() => navigate('/')}
            >
              {t('Done')}
            </Button>
          </div>
        </div>
      </PostVideoVisitLayoutContent>
    </PostVideoVisitLayout>
  );
};

export default PostVideoVisitPage;
