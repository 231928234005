import { useMutation } from '@tanstack/react-query';
import { CircleBack, CircleSubmit } from 'components/forms';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { client } from 'constants/apiV2Clients';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export function IntakesConfirmationPage() {
  const { accountId } = usePatient();
  const navigate = useNavigate();

  const { t } = useTranslation('scheduling');

  // Current form ID (created from the workflow)
  const { formId } = useParams<{ formId: string }>();
  if (formId === undefined) {
    throw new Error('Workflow ID is required');
  }

  const [searchParams] = useSearchParams();

  const searchQuery = searchParams.get('query');
  const lastQuestionId = searchParams.get('lastQuestionId');
  const formName = searchParams.get('formName');

  const mutation = useMutation({
    mutationFn: async () => {
      return client.workflowsApi.workflowsAccountsFormsCompletePartialUpdate(
        accountId,
        formId
      );
    },
    onSuccess: response => {
      // If the form is complete, navigate to scheduling
      if (response.status === 200) {
        navigate({
          pathname: `/schedule`,
          search: `?formId=${formId}&query=${searchQuery}&formName=${formName}`,
        });
      }
    },
  });

  const canGoBack = lastQuestionId !== null;
  const handleBack = () => {
    navigate(
      `/intakes/${formId}?query=${searchQuery}&firstQuestionId=${lastQuestionId}&formName=${formName}`
    );
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        mutation.mutate();
      }}
    >
      <IntakesLayout step="schedule">
        <IntakesLayoutContent>
          <div className="flex h-full w-full flex-col items-center justify-center gap-2 text-center">
            <div className="w-96 font-serif text-4xl font-light leading-10">
              {t('Ready to book your appointment?')}
            </div>
            <div className="w-96 font-sans text-xl font-light">
              {t(
                'If needed, now’s a good time to review your responses to the intake before continuing.'
              )}
            </div>
          </div>
        </IntakesLayoutContent>
        <IntakesLayoutFooter>
          <div>{canGoBack && <CircleBack onClick={handleBack} />}</div>
          <CircleSubmit valid loading={mutation.isLoading} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
