import {
  Heading,
  Link,
  PageLayout,
  PageLayoutContent,
  Paragraph,
} from 'components';
import { Button } from 'components/forms';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

export function PediatricsIneligible() {
  const { t } = useTranslation('onboarding');
  const { logout } = useAuth0();

  return (
    <PageLayout progress={9 / 9}>
      <PageLayoutContent centered>
        <Heading>{t('Pediatric support is not available')}</Heading>
        <Paragraph>
          {t(
            'Your membership does not currently include care for patients under 18.'
          )}
        </Paragraph>
        <Paragraph>{t('Please contact us for further detail.')}</Paragraph>

        <br />

        <Button type="primary" onClick={logout}>
          {t('Exit')}
        </Button>

        <br />
        <br />

        <Link href="mailto:support@galileohealth.com">{t('Contact Us')}</Link>
      </PageLayoutContent>
    </PageLayout>
  );
}
