import React from 'react';
import { cn } from 'lib/util';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { IntakesProgress, IntakesProgressProps } from './IntakesProgress';

type IntakesLayoutProps = {
  step: IntakesProgressProps['step'];
  children: React.ReactNode;
};

export function IntakesLayout(props: IntakesLayoutProps) {
  const { step, children } = props;

  return (
    <div className="bg-ivory-2 intakes-layout-mobile md:intakes-layout-desktop">
      <div className="p-4">
        <Link to="/">
          <CloseOutlined className="text-xl text-black" />
        </Link>
      </div>
      <div className="py-3">
        <IntakesProgress step={step} />
      </div>
      <div>{/* SPACER */}</div>

      <main className="relative col-span-3 rounded-md bg-white p-4 pt-8 text-black onboarding-layout-mobile-sub md:col-span-1 md:col-start-2 md:p-8 md:drop-shadow">
        {children}
      </main>
    </div>
  );
}

type IntakesLayoutContentProps = {
  children: React.ReactNode;
  className?: string;
};

export function IntakesLayoutContent(props: IntakesLayoutContentProps) {
  const { children, className } = props;

  return <section className={cn('pb-8', className)}>{children}</section>;
}

type IntakesLayoutFooterProps = {
  children: React.ReactNode;
};

export function IntakesLayoutFooter(props: IntakesLayoutFooterProps) {
  const { children } = props;
  const hasExtraContent = React.Children.count(children) > 1;

  return (
    <section
      className={cn(
        'grid items-center pb-8 md:pb-0',
        hasExtraContent
          ? 'grid-cols-[1fr_min-content] '
          : 'grid-cols-[min-content] place-content-end'
      )}
    >
      {children}
    </section>
  );
}
