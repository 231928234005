import { Lab, Prescription } from 'models/todo';
import { create } from 'zustand';

type HomePageState = {
  patientLatLong: google.maps.LatLngLiteral | null;
  setPatientLatLong: (patientLatLong: google.maps.LatLngLiteral | null) => void;

  showTaskModal: boolean;
  setShowTaskModal: (showTaskModal: boolean) => void;

  labTask: Lab[] | [];
  setLabTask: (labTask: Lab[] | []) => void;

  prescriptionTask: Prescription[] | [];
  setPrescriptionTask: (prescriptionTask: Prescription[] | []) => void;
};

export const useHomePageStore = create<HomePageState>(set => ({
  patientLatLong: null,
  setPatientLatLong: patientLatLong => set({ patientLatLong }),

  showTaskModal: false,
  setShowTaskModal: showTaskModal => set({ showTaskModal }),

  labTask: [],
  setLabTask: labTask => set({ labTask }),

  prescriptionTask: [],
  setPrescriptionTask: prescriptionTask => set({ prescriptionTask }),
}));
