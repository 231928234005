import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  Heading,
  Paragraph,
} from 'components';
import {
  CircleBack,
  CircleSubmit,
  GenericError,
  TextInput,
} from 'components/forms';
import { useMutation } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import i18n from 'i18n';
import { useNewUserOnboardingStore } from './store';
import { requestPhoneConfirmationService } from './services';

const schema = z.object({
  phoneNumber: z
    .string()
    .trim()
    .length(10, { message: i18n.t('is required') }),
});
type Schema = z.infer<typeof schema>;

export function PhoneNumberEntryForm() {
  const { t } = useTranslation('onboarding');
  const { accountId } = usePatient();
  const [setPhoneNumber, setCurrentStep, storedPhoneNumber] =
    useNewUserOnboardingStore(state => [
      state.setPhoneNumber,
      state.setCurrentStep,
      state.phoneNumber,
    ]);
  if (!accountId) throw new Error('accountID is required');

  const { control, handleSubmit, setError, formState, watch, setValue } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: {
        phoneNumber: storedPhoneNumber,
      },
    });

  const query = useMutation({
    mutationFn: (data: Schema) => {
      return requestPhoneConfirmationService({
        accountId,
        countryCode: '1',
        ...data,
      });
    },
    onSuccess: (_, inputs) => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_PHONE_NUMBER_SCREEN);
      setPhoneNumber(inputs);
      setCurrentStep('PHONE_NUMBER_CONFIRMATION');
    },
    onError: () => {
      setError('root.serverError', {
        message: t('Something went wrong. Please try again.'),
      });
    },
  });

  const onBack = () => {
    setCurrentStep('ADDRESS_ENTRY');
  };

  const cleanPhoneNumber = (phoneNumber: string) => {
    const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
    return formattedPhoneNumber;
  };

  const enteredPhoneNumber = watch('phoneNumber');
  useEffect(() => {
    if (!watch('phoneNumber')) return;
    const number = cleanPhoneNumber(enteredPhoneNumber);
    setValue('phoneNumber', number);
  }, [enteredPhoneNumber, setValue, watch]);

  return (
    <form onSubmit={handleSubmit(data => query.mutate(data))}>
      <PageLayout progress={3 / 9}>
        <PageLayoutContent>
          <Heading>{t('Phone number')}</Heading>
          <Paragraph type="note">
            {t('We will only contact you in the event of a medical emergency.')}
          </Paragraph>

          <GenericError message={formState.errors.root?.serverError?.message} />

          <TextInput
            type="tel"
            name="phoneNumber"
            label={t('Phone number')}
            control={control}
            maxLength={10}
            required
          />
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit valid={formState.isValid} loading={query.isLoading} />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
