import { useAuth0 } from '@auth0/auth0-react';
import { env } from 'lib/env';
import { FC } from 'react';
import { ReactComponent as GalileoLogoLight } from 'assets/galileo_logo.svg';
import { ReactComponent as GalileoLogoDark } from 'assets/galileo-logo-dark.svg';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  dark?: boolean;
};

export const Header: FC<HeaderProps> = ({ dark }) => {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <header
      className={`col-span-3 my-4 flex h-1 items-center justify-between p-4 ${
        dark ? 'text-white' : 'text-black'
      }`}
    >
      {dark ? (
        <GalileoLogoLight className="w-24" />
      ) : (
        <GalileoLogoDark className="w-24" />
      )}
      <button
        type="button"
        onClick={() =>
          isAuthenticated
            ? logout({
                logoutParams: {
                  returnTo: env.VITE_APP_ORIGIN,
                },
              })
            : loginWithRedirect({
                appState: {
                  returnTo: '/',
                },
              })
        }
      >
        {isAuthenticated ? t('Logout') : t('Login')}
      </button>
    </header>
  );
};
