import { client } from 'constants/apiV2Clients';

export const createZoomJWTSignatureService = async (
  accountId: string,
  meetingNumber: string,
  role: number
) => {
  const response =
    await client.videoConsultationsApi.videoConsultationsAccountsMeetingJwtCreate(
      accountId,
      { meeting_number: meetingNumber, role }
    );
  return response.data;
};
