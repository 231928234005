import { StateEnum } from '@galileo/core-api-client';
import { InputRef } from 'antd';
import { isGoogleMapsPlacesLibraryLoaded } from 'helpers/googleMaps';
import { useEffect, useRef } from 'react';
import { getStateFromString } from '../../features/NewUserOnboarding/util.ts';

export type AutoCompleteAddress = {
  street?: string;
  city?: string;
  state?: StateEnum;
  zip?: string;
};

type Props = {
  isAddressAutoComplete: boolean;
  onPlaceSelected?: (result: AutoCompleteAddress) => void;
};

export function useAddressAutoComplete(props: Props) {
  const { isAddressAutoComplete, onPlaceSelected } = props;

  const inputRef = useRef<InputRef>();
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();

  useEffect(() => {
    if (!isAddressAutoComplete) {
      return;
    }
    if (!inputRef || !inputRef.current || !inputRef.current.input) {
      return;
    }
    if (!autoCompleteRef || !isGoogleMapsPlacesLibraryLoaded()) {
      return;
    }

    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current.input,
      { types: ['address'], componentRestrictions: { country: 'us' } }
    );

    if (!autoCompleteRef.current) {
      return;
    }

    autoCompleteRef.current.setFields([
      'address_components',
      'formatted_address',
    ]);

    const listener = google.maps.event.addListener(
      autoCompleteRef.current,
      'place_changed',
      () => {
        if (!onPlaceSelected) {
          return;
        }
        const result = autoCompleteRef.current?.getPlace();
        const components = result?.address_components ?? null;

        if (!components) {
          return;
        }

        const street = [
          components.find(component =>
            component.types.includes('street_number')
          )?.long_name,
          components.find(component => component.types.includes('route'))
            ?.long_name,
        ].join(' ');

        const city = components.find(component =>
          component.types.includes('locality')
        )?.long_name;

        const state = getStateFromString(
          components.find(component =>
            component.types.includes('administrative_area_level_1')
          )?.short_name
        );

        const zip = components.find(component =>
          component.types.includes('postal_code')
        )?.long_name;

        onPlaceSelected({ street, city, state, zip });
      }
    );

    return () => {
      google.maps.event.removeListener(listener);
      document
        .querySelectorAll('.pac-container')
        .forEach(container => container.remove());
    };
  }, [isAddressAutoComplete, onPlaceSelected]);

  return inputRef;
}
