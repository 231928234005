import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { API, flaskApiClient } from 'constants/apiEndpoints';

type UsePhotoResult = {
  isLoading: boolean;
  isPending: boolean;
  isValid: boolean;
  photoUrl?: string;
  uploadPhoto: (file: File) => void;
  deletePhoto: () => void;
};

export function usePhotoIdUpload(accountId: string): UsePhotoResult {
  const queryClient = useQueryClient();
  const [photoUrl, setPhotoUrl] = useState<string | undefined>();

  /**
   * This function attempts to convert any image file to a JPEG file.
   * Flask API requires images to be in JPEG format.
   * The IdUploadForm component has already checked that the file is an image.
   *
   * @param file - The image file to convert to JPEG
   * @returns The converted JPEG file
   */
  const convertFileToJpeg = async (file: File) => {
    // If the file is already a JPEG, return it
    if (file.type === 'image/jpeg') return file;

    // Convert the file to a base64 string
    const base64File: unknown = await convertBlobToBase64(file);
    if (typeof base64File !== 'string') {
      throw new Error('Failed to convert blob to base64');
    }

    // Get the file type from the base64 string and replace it with 'data:image/jpeg'
    const fileType = base64File.substring(0, base64File.indexOf(';'));
    const base64JPEG = base64File.replace(fileType, 'data:image/jpeg');

    // Fetch the base64 JPEG and convert it to a blob
    const base64Response = await fetch(base64JPEG);
    const jpegBlob = await base64Response.blob();

    // Return the blob as a file
    return new File([jpegBlob], `${file.name}.jpeg`, {
      type: 'image/jpeg',
    });
  };

  /**
   * This function converts a blob to a base64 string.
   *
   * @param blob - The blob to convert
   * @returns The base64 string
   */
  const convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  const query = useQuery({
    queryKey: ['photo', accountId],
    queryFn: async () => {
      try {
        const response = await flaskApiClient.get(
          API.accountImagesIdentification(accountId),
          {
            responseType: 'blob',
          }
        );

        // Catch any non-error responses that aren't a full success
        if (response.status !== 200) return null;

        const blob = response.data;
        const url = URL.createObjectURL(blob);

        setPhotoUrl(url);
        return url;
      } catch (error) {
        // Catch any 404s or 500s, etc
        return null;
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  const uploadMutation = useMutation({
    mutationKey: ['photo', accountId],
    mutationFn: async (file: File) => {
      // Convert the file to a JPEG
      const jpegFile = await convertFileToJpeg(file);
      setPhotoUrl(URL.createObjectURL(jpegFile));

      const formData = new FormData();
      formData.append('image', jpegFile);

      await flaskApiClient.put(
        API.accountImagesIdentification(accountId),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['photo', accountId]);
    },
  });

  const deleteMutation = useMutation({
    mutationKey: ['photo', accountId],
    mutationFn: async () => {
      await flaskApiClient.delete(API.accountImagesIdentification(accountId));
    },
    onSuccess: () => {
      setPhotoUrl(undefined);
      queryClient.invalidateQueries(['photo', accountId]);
    },
  });

  const { isLoading } = query;
  const isPending = uploadMutation.isLoading || deleteMutation.isLoading;
  const isValid = Boolean(
    !query.isError && !uploadMutation.isError && photoUrl
  );
  const updatePhoto = uploadMutation.mutate;
  const deletePhoto = deleteMutation.mutate;

  return {
    isLoading,
    isPending,
    isValid,
    photoUrl,
    uploadPhoto: updatePhoto,
    deletePhoto,
  };
}
