import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';

export const zoomClient = ZoomMtgEmbedded.createClient();

export interface ZoomConfig {
  accountId: string;
  meetingNumber: string;
  password: string;
  userName: string;
}
