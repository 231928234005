import { Heading, PageLayout, PageLayoutContent, Paragraph } from 'components';
import { Button } from 'components/forms';
import Lottie from 'lottie-react';
import successAnimation from 'assets/success-animation.json';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { useTranslation } from 'react-i18next';

export function InsuranceConfirmation() {
  const { t } = useTranslation('onboarding');
  const { refetchAccount } = usePatient();

  const handleContinue = () => {
    refetchAccount();
  };

  return (
    <PageLayout whiteBackground progress={9 / 9}>
      <PageLayoutContent centered>
        <div className="grid place-items-center pb-8">
          <Lottie
            className="w-32"
            animationData={successAnimation}
            loop={false}
          />
        </div>

        <Heading>{t('Congratulations, your account is activated!')}</Heading>
        <Paragraph className="mb-8">
          {t(
            'You can now access 24/7 medical care and all other Galileo services.'
          )}
        </Paragraph>
        <br />
        <Button type="primary" onClick={handleContinue}>
          {t('Continue')}
        </Button>
      </PageLayoutContent>
    </PageLayout>
  );
}
