import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type PageLayoutProps = {
  children: React.ReactNode;
  onClose: () => void;
};

export function PostVideoVisitLayout(props: PageLayoutProps) {
  const { children, onClose } = props;
  return (
    <div
      className="grid-rows-[auto, 1fr] grid min-h-screen bg-space-10"
      style={{
        backgroundPosition: '52% 50%',
        gridTemplateRows: '10rem, auto',
      }}
    >
      <header className="grid grid-cols-[auto,1fr,auto] px-4 py-4">
        <Button
          className="justify-self-start"
          style={{ color: 'white', border: 'none' }}
          icon={<CloseOutlined />}
          shape="circle"
          onClick={() => {
            onClose();
          }}
        />
      </header>
      <main className="container mx-auto px-4 py-8">{children}</main>
    </div>
  );
}

type PageLayoutContentProps = {
  children: React.ReactNode;
};

export function PostVideoVisitLayoutContent(props: PageLayoutContentProps) {
  const { children } = props;
  return <section className="pb-8 text-white">{children}</section>;
}
