import { cn } from 'lib/util';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  type?: 'primary' | 'default' | 'ghost';
  dataTestId?: string;
};

export function Button(props: ButtonProps) {
  const { children, onClick, dataTestId } = props;
  const type = props.type ?? 'default';

  return (
    <button
      type="button"
      className={cn(
        'h-16 rounded-full border border-space-10 px-7 py-2.5 text-lg hover:border-space-10',
        type === 'primary' && 'bg-space-10 text-white',
        type === 'ghost' && 'bg-transparent text-space-10'
      )}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
}
