import { FlaskApiCareplans } from '@galileo/core-api-client';
import { useQuery } from '@tanstack/react-query';

import { usePatient } from 'providers/OnboardedPatientProvider';
import { useState } from 'react';
import { Todo } from 'models/todo';
import { InnerSubHeading } from 'components';
import { useTranslation } from 'react-i18next';
import { getCareplanPatientTodosService } from './services';
import { PatientTodos } from './Todos';

type CarePlanProps = {
  careplan: FlaskApiCareplans;
};

export const CarePlan = (props: CarePlanProps) => {
  const { t } = useTranslation('home');
  const { patientAccount } = usePatient();
  const { careplan } = props;
  const [todos, setTodos] = useState<Todo[]>([]);
  const [isCareplanLoading, setIsCareplanLoading] = useState(false);
  const dateAddedString = careplan?.added_at ? careplan.added_at : '';
  const dateAdded = new Date(dateAddedString);

  useQuery({
    queryKey: ['todosKeys', patientAccount, careplan],
    queryFn: async () => {
      setIsCareplanLoading(true);
      const response = await getCareplanPatientTodosService(
        patientAccount.account_id,
        careplan.careplan_id
      );

      const { prescriptions, lab_tests } = response.data[0];
      setTodos([...prescriptions, ...lab_tests]);
      setIsCareplanLoading(false);
      return response;
    },
    onError: error => {
      console.error('Unable to get careplan todos', error);
      setIsCareplanLoading(false);
    },
  });

  return (
    <>
      <div
        className="inline-flex items-center justify-start gap-2.5 self-stretch"
        data-testid="careplan"
      >
        <InnerSubHeading className="shrink grow basis-0 text-left">
          {t('Your Care Plan')}
        </InnerSubHeading>
        <div className="text-right text-sm font-normal leading-4 text-charcoal-6">
          {dateAdded.toLocaleDateString()}
        </div>
      </div>
      <div
        data-testid="careplanDescription"
        className="self-stretch text-left text-base font-normal leading-tight text-zinc-800"
      >
        {careplan?.description}
      </div>
      <PatientTodos todos={todos} isLoading={isCareplanLoading} />
    </>
  );
};
