import { ArrowLeftOutlined } from '@ant-design/icons';

type CircleBackProps = {
  onClick: () => void;
};

export function CircleBack(props: CircleBackProps) {
  const { onClick } = props;

  return (
    <button
      className="grid h-16 w-16 appearance-none grid-cols-1 place-items-center rounded-full border border-charcoal-2 p-0"
      onClick={onClick}
      aria-label="Back"
      type="button"
    >
      <ArrowLeftOutlined className="h-8 text-xl text-charcoal-8" />
    </button>
  );
}
