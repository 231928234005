import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Merges class names via clsx() and twMerge()
 * @inputs - One or more clsx-style inputs
 */
export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

/**
 * Asserts that a value is never. This is useful for exhaustiveness checking
 * in switch statements
 * @link https://dev.to/ddiprose/exhaustive-switch-statement-with-typescript-26dh
 * @value - The value to assert
 */
export function assertUnreachable(value: never): never {
  throw new Error(`Unhandled case: ${value}`);
}
