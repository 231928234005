import { FC } from 'react';
import { Heading } from 'components';
import { ReactComponent as GalileoVideoVisitLogo } from 'assets/galileo_video_visit_logo.svg';
import { useTranslation } from 'react-i18next';
import { Appointment } from '../../models';

interface AppointmentConfirmationProps {
  appointment: Appointment;
}

export const AppointmentConfirmation: FC<AppointmentConfirmationProps> = ({
  appointment,
}) => {
  const { t } = useTranslation('home');
  const appointmentDatetime = new Date(appointment.datetime);
  const date = appointmentDatetime.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const time = appointmentDatetime.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  });

  return (
    <section
      data-testid="appointmentConfirmation"
      className="flex flex-col items-center text-center"
    >
      <Heading>{t('Your appointment is scheduled.')}</Heading>
      <div className="mx-auto my-5 w-52 max-w-md space-y-2 rounded-md bg-white pt-8 sm:flex sm:flex-col sm:items-center sm:justify-center sm:space-y-0 sm:pt-4">
        <GalileoVideoVisitLogo className="mx-auto block h-20 rounded-full sm:mx-0 sm:shrink-0" />

        <div className="space-y-2 pb-4 text-center">
          <p className="text-lg font-semibold text-black">{date}</p>
          <p className="text-slate-500 font-medium">{time}</p>
        </div>

        <div
          style={{ borderTop: '1px solid #ccc' }}
          className="my-5 flex w-full items-center justify-evenly py-2"
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={appointment.confirmationPage}
            className="text-sm no-underline"
          >
            {t('Reschedule')}
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={appointment.confirmationPage}
            className="text-sm no-underline"
          >
            {t('Cancel')}
          </a>
        </div>
      </div>
      <div className="my-5 max-w-xs items-center text-center">
        <p>
          {t(
            'Please come back to this page at the time of your appointment to join the video visit.'
          )}
        </p>
      </div>
    </section>
  );
};
