import { z } from "zod";

/**
 * Configuration for the app based on environemnt variables parsed through a
 * Zod schema.
 */
export const env = z
  .object({
    VITE_ENVIRONMENT: z.enum(["development", "staging", "production"]),
    VITE_DATADOG_TOKEN: z.string(),
    VITE_MIXPANEL_TOKEN: z.string(),
    VITE_SPLIT_CLIENT_API_KEY: z.string(),
    VITE_SEGMENT_WRITE_KEY: z.string(),
    VITE_AUTH0_DOMAIN: z.string(),
    VITE_AUTH0_CLIENT_ID: z.string(),
    VITE_APP_ORIGIN:z.string(),
    VITE_AUTH0_AUDIENCE: z.string(),
    VITE_APP_GALILEO_API_PROTOCOL: z.string(),
    VITE_APP_GALILEO_API_URL: z.string(),
    VITE_APP_GALILEO_API_VERSION: z.string(),
    VITE_ZOOM_MEETING_CLIENT_ID: z.string(),
    VITE_CORE_API_BASEPATH: z.string(),
    VITE_GOOGLE_MAPS_API_KEY: z.string(),
  })
  .parse(import.meta.env);
