import { useAuth0 } from '@auth0/auth0-react';
import { FullPageLoading } from 'components';
import type { ReactElement } from 'react';
import { useEffect } from 'react';

type AuthRequiredBoundaryProps = {
  children: ReactElement;
};

export function AuthRequiredBoundary(props: AuthRequiredBoundaryProps) {
  const { children } = props;
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  // Force a login request if user is not authenticated
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: '/',
        },
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading || !isAuthenticated) return <FullPageLoading />;

  return children;
}
