import { client } from 'constants/apiV2Clients';
import { usePatient } from 'providers/OnboardedPatientProvider';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { useTranslation } from 'react-i18next';
import { Heading, IntakeKicker } from 'components';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircleSubmit, SearchInput } from 'components/forms';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const schema = z.object({
  search: z.string().nonempty().trim(),
});
type Schema = z.infer<typeof schema>;

export function IntakesSearchPage() {
  const { t } = useTranslation('intakes');
  const navigate = useNavigate();
  const { accountId } = usePatient();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { control, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
  });
  const onlyValidForWeb = true;

  const onSubmit = handleSubmit(async data => {
    // Create the new form and navigate to the intake filler-outer
    const response = await client.workflowsApi.workflowsAccountsFormsCreate(
      accountId,
      { form_workflow_id: data.search }
    );
    const { id, first_question_id, name } = response.data;
    navigate(
      `/intakes/${id}?query=${searchQuery}&firstQuestionId=${first_question_id}&formName=${name}`
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <IntakesLayout step="search">
        <IntakesLayoutContent>
          <IntakeKicker>{t('Consult our medical experts')}</IntakeKicker>

          <Heading>{t('How can we help?')}</Heading>

          <label htmlFor="search" className="mb-1 block text-charcoal-12">
            {t('Select Issue')}
          </label>
          <SearchInput
            autoFocus
            control={control}
            name="search"
            onSearch={async value => {
              const response =
                await client.workflowsApi.workflowsAccountsFormsSearchList(
                  accountId,
                  value,
                  undefined,
                  onlyValidForWeb
                );

              setSearchQuery(value);

              const options = response.data
                .filter(item => item.form_workflow_id !== null)
                .map(item => ({
                  label: item.name,
                  value: item.form_workflow_id,
                }));

              return options;
            }}
          />
        </IntakesLayoutContent>
        <IntakesLayoutFooter>
          <CircleSubmit valid={formState.isValid} loading={false} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
