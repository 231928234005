import { create } from 'zustand';
import { parseGenderForFlaskApi, State } from './util';
import {
  FlaskApiGender,
  GenderIdentity,
  SexAssignedAtBirth,
} from './constants';

type NewUserOnboardingSteps =
  | 'NAME_ENTRY'
  | 'BIOLOGICAL_SEXES_AND_DOB'
  | 'PHONE_NUMBER_ENTRY'
  | 'PHONE_NUMBER_CONFIRMATION'
  | 'ADDRESS_ENTRY'
  | 'ID_UPLOAD'
  | 'EMPLOYEE_SPONSORSHIP'
  | 'INSURANCE_ENTRY'
  | 'INSURANCE_CONFIRMATION'
  | 'PEDIATRICS_INELIGIBLE'
  | 'GALILEO_ELIGIBLE'
  | 'SPONSORSHIP_REQUIRED'
  | 'WEB_ACCESS_REQUIRED';

type NewUserOnboardingState = {
  currentStep: NewUserOnboardingSteps;
  setCurrentStep: (currentStep: NewUserOnboardingSteps) => void;

  syncPhoneNumberResult?: string;
  setSyncPhoneNumberResult: (syncPhoneNumberResult: string) => void;

  firstName?: string;
  lastName?: string;
  preferredName?: string;
  acceptedTerms?: boolean;
  setName: (props: {
    firstName: string;
    lastName: string;
    preferredName?: string;
    acceptedTerms: boolean;
  }) => void;

  dateOfBirth?: Date;
  sexAssignedAtBirth?: SexAssignedAtBirth;
  gender?: FlaskApiGender;
  genderDisplay?: string;
  genderIdentity?: GenderIdentity;
  otherGenderIdentity?: string;
  setBiologicalSexesAndDOB: (props: {
    dateOfBirth: string;
    sexAssignedAtBirth: SexAssignedAtBirth;
    genderIdentity?: GenderIdentity;
    otherGenderIdentity?: string;
  }) => void;

  street?: string;
  city?: string;
  state?: State;
  zip?: string;
  setAddress: (props: {
    street: string;
    city: string;
    state: State;
    zip: string;
  }) => void;

  phoneNumber?: string;
  setPhoneNumber: (prop: { phoneNumber: string }) => void;

  phoneConfirmed: boolean;
  setPhoneConfirmed: () => void;

  insuranceProvider?: string;
  insuranceMemberID?: string;
  isNotInsured?: boolean;
  setInsuranceInformation: (props: {
    insuranceProvider: string;
    insuranceMemberID: string;
    isNotInsured: boolean;
  }) => void;

  employeeSponsorshipAccessCode?: string;
  setEmployeeSponsorship: (props: {
    employeeSponsorshipAccessCode: string;
  }) => void;

  hasInsurance: boolean;
  setHasInsurance: (hasInsurance: boolean) => void;

  isSponsored: boolean;
  setIsSponsored: (isSponsored: boolean) => void;

  checkedForInsuranceAndSponsorship: boolean;
  setCheckedForInsuranceAndSponsorship: () => void;
};

export const useNewUserOnboardingStore = create<NewUserOnboardingState>(
  set => ({
    currentStep: 'NAME_ENTRY',
    setCurrentStep: currentStep => set({ currentStep }),

    syncPhoneNumberResult: undefined,
    setSyncPhoneNumberResult(syncPhoneNumberResult: string) {
      set({ syncPhoneNumberResult });
    },

    setName: ({ firstName, lastName, preferredName, acceptedTerms }) =>
      set({ firstName, lastName, preferredName, acceptedTerms }),

    setBiologicalSexesAndDOB: ({
      dateOfBirth,
      sexAssignedAtBirth,
      genderIdentity,
      otherGenderIdentity,
    }) => {
      const [flaskApiGender, genderId, genderDisplay] = parseGenderForFlaskApi(
        sexAssignedAtBirth,
        genderIdentity,
        otherGenderIdentity
      );
      set({
        dateOfBirth: new Date(dateOfBirth),
        sexAssignedAtBirth,
        gender: flaskApiGender,
        genderIdentity: genderId,
        genderDisplay,
      });
    },

    setAddress: ({ street, city, state, zip }) =>
      set({ street, city, state, zip }),

    setPhoneNumber: ({ phoneNumber }) => set({ phoneNumber }),

    phoneConfirmed: false,
    setPhoneConfirmed: () => set({ phoneConfirmed: true }),

    setInsuranceInformation: ({
      insuranceProvider,
      insuranceMemberID,
      isNotInsured,
    }) => {
      set({ insuranceProvider, insuranceMemberID, isNotInsured });
    },

    setEmployeeSponsorship: ({ employeeSponsorshipAccessCode }) =>
      set({ employeeSponsorshipAccessCode }),

    hasInsurance: false,
    setHasInsurance: hasInsurance => set({ hasInsurance }),

    isSponsored: false,
    setIsSponsored: isSponsored => set({ isSponsored }),

    checkedForInsuranceAndSponsorship: false,
    setCheckedForInsuranceAndSponsorship: () =>
      set({ checkedForInsuranceAndSponsorship: true }),
  })
);
