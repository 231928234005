import { AutoComplete as AntAutoComplete } from 'antd';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export interface MenuOption {
  key: string;
  value: string;
  label: string;
}

export type AutoCompleteProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  options: { key: string; value: string; label: string }[];
  placeholder: string;
  disabled?: boolean;
};

export function InsuranceAutoComplete<TFieldValues extends FieldValues>(
  props: AutoCompleteProps<TFieldValues>
) {
  const { Option } = AntAutoComplete;

  const { control, name, options, placeholder, disabled } = props;

  const { field } = useController({
    control,
    name,
  });

  const getValue = (inputValue: string) => {
    return {
      value:
        options.find((option: MenuOption) => option.value === inputValue)
          ?.label ?? inputValue,
    };
  };

  const selectPlaceholder = () => {
    return (
      <div className="flex">
        <div className="text-lg text-charcoal-6">{placeholder}</div>
      </div>
    );
  };

  return (
    <AntAutoComplete
      id={name}
      className="w-full"
      options={options}
      onChange={field.onChange}
      onSelect={field.onChange}
      onClear={() => {
        field.onChange('');
      }}
      value={getValue(field.value)}
      disabled={disabled}
      placeholder={selectPlaceholder()}
      allowClear
      filterOption={(inputValue, option) => {
        return (
          option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        );
      }}
      data-testid="insurance-auto-complete"
    >
      {options.map(item => (
        <Option key={item.key} value={item.value} className="text-lg">
          <div>{item.label}</div>
        </Option>
      ))}
    </AntAutoComplete>
  );
}
