export default {
  /** Allows query string override of useragent for testing */
  userAgent() {
    const searchParams = new URLSearchParams(window?.location.search);
    return searchParams.get('userAgentOverride') || navigator.userAgent;
  },
  isAndroid() {
    return /Android/i.test(this.userAgent());
  },
  isIOS() {
    return /iPhone|iPad|iPod/i.test(this.userAgent());
  },
  isMobile() {
    return this.isAndroid() || this.isIOS();
  },
};
