import { useSearchParams } from 'react-router-dom';

export function usePersistQueryParams(queryParams: string[]) {
  const [searchParams] = useSearchParams();

  queryParams.forEach(queryParam => {
    const value = searchParams.get(queryParam);
    if (value) {
      localStorage.setItem(queryParam, value);
    }
  });
}

interface CachedQueryParams {
  [key: string]: string | null;
}

export function useCachedQueryParams(paramNames: string[]): CachedQueryParams {
  const values: CachedQueryParams = {};

  // Check to see if value exists in params
  const [searchParams] = useSearchParams();
  paramNames.forEach(paramName => {
    const value = searchParams.get(paramName);
    if (value) {
      localStorage.setItem(paramName, value);
      values[paramName] = value;
    }

    // Check to see if value exists in localStorage
    values[paramName] = localStorage.getItem(paramName);
  });

  return values;
}
