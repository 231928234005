import { useAuth0 } from '@auth0/auth0-react';
import { Patient } from '@galileo/core-api-client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FullPageLoading } from 'components';
import { API, flaskApiClient } from 'constants/apiEndpoints';
import { NewUserOnboarding } from 'features/NewUserOnboarding';
import { searchPatientService } from 'features/NewUserOnboarding/services';
import { RegistrationCompleteLockout } from 'features/pg-RegistrationCompleteLockout';
import { Account, ApiResponse } from 'models';
import { create } from 'zustand';

type State = {
  patientAccount: Account | null;
  patient: Patient | null;
  setPatientAccount: (account: Account) => void;
  setPatient: (patient: Patient) => void;
  isOnboarded: boolean;
  canOpenCases: boolean;
  canAccessWeb: boolean;
};

const useStore = create<State>()(set => ({
  patientAccount: null,
  patient: null,
  isOnboarded: false,
  canOpenCases: false,
  canAccessWeb: false,
  setPatientAccount: account =>
    set({
      patientAccount: account,
      isOnboarded: account.registration_state === 'verified',
      canOpenCases: account.entitlements.includes('can_open_cases'),
      canAccessWeb: account.entitlements.includes('can_access_web'),
    }),
  setPatient: patient => set({ patient }),
}));

// eslint-disable-next-line react-refresh/only-export-components
export function usePatient() {
  const queryClient = useQueryClient();
  const [patientAccount, patient, isOnboarded, canOpenCases, canAccessWeb] =
    useStore(state => [
      state.patientAccount,
      state.patient,
      state.isOnboarded,
      state.canOpenCases,
      state.canAccessWeb,
    ]);
  if (!patientAccount) throw new Error('Invalid account');

  return {
    accountId: (patientAccount as Account).account_id,
    patientId: (patient as Patient).id,
    patientAccount: patientAccount as Account,
    patient: patient as Patient,
    isOnboarded,
    canOpenCases,
    canAccessWeb,
    refetchAccount: () => {
      // Invalidate patient-related queries and refetch them
      // NOTE: Each queryKey array is a separate key
      queryClient.invalidateQueries({
        queryKey: ['patientAccount'],
      });
      queryClient.invalidateQueries({
        queryKey: ['patientProfile'],
      });
    },
  };
}

type OnboardedPatientProviderProps = {
  children: React.ReactNode;
};

export function OnboardedPatientProvider(props: OnboardedPatientProviderProps) {
  const { children } = props;
  const { user } = useAuth0();
  const accountId = user?.['https://galileo.io/user_metadata.account_id'];
  const [
    setPatientAccount,
    setPatient,
    isOnboarded,
    canOpenCases,
    canAccessWeb,
  ] = useStore(state => [
    state.setPatientAccount,
    state.setPatient,
    state.isOnboarded,
    state.canOpenCases,
    state.canAccessWeb,
  ]);

  const accountQuery = useQuery({
    queryKey: ['patientAccount', accountId],
    queryFn: async () => {
      const response = await flaskApiClient.get<ApiResponse<Account>>(
        API.account(accountId)
      );
      const account = response.data.data[0];
      setPatientAccount(account);
      return account;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const patientQuery = useQuery({
    queryKey: ['patientProfile', accountId],
    queryFn: async () => {
      const [patient] = await searchPatientService(accountId);
      setPatient(patient);
      return patient;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  if (accountQuery.isLoading || patientQuery.isLoading)
    return <FullPageLoading />;
  if (!accountId) throw new Error('Invalid user');

  if (!isOnboarded) return <NewUserOnboarding />;
  if (isOnboarded && !canOpenCases)
    return <RegistrationCompleteLockout hasActiveMembership={false} />;
  if (isOnboarded && !canAccessWeb)
    return <RegistrationCompleteLockout hasActiveMembership />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
