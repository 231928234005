import { useCookies } from 'react-cookie';
import isBot from 'isbot';
import { useCallback } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { env } from '../lib/env';

const analytics = AnalyticsBrowser.load({
  writeKey: env.VITE_SEGMENT_WRITE_KEY,
});

type GenericObject = {
  [name: string]: any;
};

export const cookiesToProperties = (cookies: GenericObject): GenericObject =>
  Object.keys(cookies)
    .filter(key => key.startsWith('utm'))
    .reduce((obj: any, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = cookies[key];
      return obj;
    }, {});

interface UseAnalyticsHook {
  trackWithSegment: (eventName: string, properties?: GenericObject) => void;
  registerWithSegment: (properties: GenericObject, accountId?: string) => void;
}

export const useAnalytics = (): UseAnalyticsHook => {
  const [cookies] = useCookies();

  const utmProperties = cookiesToProperties(cookies);

  // Links a user to their action. See
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
  const registerWithSegment = useCallback(
    (properties = {}, accountId?: string) =>
      isBot(window.navigator.userAgent) &&
      analytics.identify(accountId, properties),
    []
  );

  // Records user actions. See
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
  const trackWithSegment = (eventName: string, properties = {}) =>
    !isBot(window.navigator.userAgent) &&
    analytics.track(eventName, { ...properties, ...utmProperties });

  const hook = {
    trackWithSegment,
    registerWithSegment,
  };

  return hook;
};
