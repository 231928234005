export const FLASK_API_GENDER = [
  'male',
  'female',
  'custom',
  'unknown',
] as const;
export type FlaskApiGender = (typeof FLASK_API_GENDER)[number];

export const SEX_ASSIGNED_AT_BIRTH = ['male', 'female', 'intersex'] as const;
export type SexAssignedAtBirth = (typeof SEX_ASSIGNED_AT_BIRTH)[number];

export const GENDER_IDENTITY = [
  'Female/Woman',
  'Male/Man',
  'Trans Female/Trans Woman',
  'Trans Male/Trans Man',
  'Genderqueer',
  'Nonbinary',
  'Two-Spirit',
  'Agender',
  'Prefer Not to Say',
  'Other',
  'unknown',
] as const;
export type GenderIdentity = (typeof GENDER_IDENTITY)[number];
