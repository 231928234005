import { useAuth0 } from '@auth0/auth0-react';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { client } from 'constants/apiV2Clients';
import { flaskApiClient } from 'constants/apiEndpoints';
import { FullPageLoading } from 'components';

type AxiosAuthProviderProps = {
  children: ReactElement;
};

export function AxiosAuthProvider(props: AxiosAuthProviderProps) {
  const { children } = props;
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const [isConfigured, setIsConfigured] = useState(false);

  // Set Axios request interceptor to add access token. The interceptor uses
  // `getAccessTokenSilently` directly in order to keep tokens refreshed.
  useEffect(() => {
    const configureClients = async () => {
      if (isLoading || !isAuthenticated) return;

      try {
        const token = await getAccessTokenSilently();
        await client.setAccessToken(token);
        flaskApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
        setIsConfigured(true);
      } catch (error) {
        // If the token is fully expired, `getAccessTokenSilently` will
        // fail. This is apparently by design, so force a login redirect
        // https://github.com/auth0/auth0-react/issues/508
        loginWithRedirect({
          appState: {
            returnTo: '/',
          },
        });
      }
    };

    configureClients();
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    setIsConfigured,
  ]);

  if (!isConfigured) {
    return <FullPageLoading />;
  }
  return children;
}
