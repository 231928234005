import { VideoConsultation } from '@galileo/core-api-client';
import { isWithinInterval, addMinutes, subDays, subMinutes } from 'date-fns';
import { Appointment } from 'models';

const APPOINTMENT_BUFFER_MINUTES = 10;

export const within10MinuteInterval = (
  newAppointment: Appointment | undefined
): boolean => {
  if (!newAppointment) return false;
  const now = new Date();
  const date = new Date(newAppointment.datetime);
  const bufferBefore = subMinutes(date, APPOINTMENT_BUFFER_MINUTES);
  const bufferAfter = addMinutes(date, APPOINTMENT_BUFFER_MINUTES);

  // Patient may join within set buffer time. After time
  // has passed, they are considered a no-show
  const isWithin10Minutes = isWithinInterval(now, {
    start: bufferBefore,
    end: bufferAfter,
  });
  return isWithin10Minutes;
};

export const isNoShow = (
  consultation: VideoConsultation,
  max_no_show_screen_days: number
) => {
  return (
    new Date(consultation.expires_at) >
      subDays(new Date(), max_no_show_screen_days) &&
    new Date(consultation.expires_at) < new Date() &&
    !consultation.patient_joined_at
  );
};

// Patient can technically schedule multiple appointments at a time. Find
// and display only the soonest appointment.
export const findSoonestAppointment = (
  appointments: Appointment[] | undefined
) => {
  return appointments?.reduce((soonest: Appointment, current: Appointment) => {
    const appointmentDatetime = new Date(current.datetime);
    const soonestDatetime = soonest ? new Date(soonest.datetime) : Infinity;

    if (
      appointmentDatetime > new Date() &&
      appointmentDatetime < soonestDatetime
    ) {
      return current;
    }
    return soonest;
  });
};
