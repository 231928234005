/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */

import { Question, QuestionResponseRequest } from '@galileo/core-api-client';
import { Heading, IntakeKicker, SubHeading } from 'components';
import { CircleBack, CircleSubmit } from 'components/forms';
import {
  IntakesLayout,
  IntakesLayoutContent,
  IntakesLayoutFooter,
} from 'components/pageLayout/IntakesLayout';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QuestionPageProps } from './types';

function parseQuestionResponseIntoDefaultValues(question: Question) {
  const { question_response } = question;

  if (!question_response) return {};
  if (
    question_response.resourcetype !== 'FreeTextQuestionWorkflowStepExecution'
  )
    return {};

  return {
    free_text_response: question_response.free_text_response ?? undefined,
  };
}

export function FreeTextQuestion(props: QuestionPageProps) {
  const { t } = useTranslation('intakes');
  const { question, onSubmit, isSubmitting, onBack } = props;
  if (question.question_type !== 'FREE_TEXT') {
    throw new Error('Invalid question type');
  }

  type FormValues = {
    free_text_response?: string;
  };

  const { register, formState, handleSubmit } = useForm<FormValues>({
    defaultValues: parseQuestionResponseIntoDefaultValues(question),
  });

  const singleSelectSubmit = handleSubmit(data => {
    const response: QuestionResponseRequest = {
      free_text_response: data.free_text_response,
    };
    onSubmit(response);
  });

  return (
    <form onSubmit={singleSelectSubmit}>
      <IntakesLayout step="intake">
        <IntakesLayoutContent>
          <IntakeKicker>{question.form.name}</IntakeKicker>
          <Heading>{question.question_body}</Heading>
          {question.question_sub_body && (
            <SubHeading>{question.question_sub_body}</SubHeading>
          )}
          <textarea
            className="mt-4 w-full resize-none outline-none"
            rows={5}
            placeholder={t('Please answer...')}
            {...register('free_text_response', {
              required: question.is_required,
            })}
          />
        </IntakesLayoutContent>
        <IntakesLayoutFooter>
          <div>{onBack && <CircleBack onClick={onBack} />}</div>
          <CircleSubmit valid={formState.isValid} loading={isSubmitting} />
        </IntakesLayoutFooter>
      </IntakesLayout>
    </form>
  );
}
