import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { useContext } from 'react';
import { ISplitContextValues } from '@splitsoftware/splitio-react/types/types';
import { ACTIVE_SPLITS } from '../constants';

export type SplitState = 'loading' | boolean;

export const useSplit = (
  name: ACTIVE_SPLITS,
  accountId?: string
): SplitState => {
  const splitContext = useContext<ISplitContextValues>(SplitContext);

  const { isReady } = splitContext;
  const treatments = useTreatments([name], undefined, accountId);

  if (!isReady) {
    return 'loading';
  }

  return treatments[name].treatment === 'on';
};
