import React from 'react';
import ReactDOM from 'react-dom/client';
import { initLogging } from 'lib/log';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'providers/AuthProvider.tsx';
import App from './App.tsx';
import './i18n';

import './index.css';

// Set up DataDog logging so we can catch errors
initLogging();

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#131238',
              },
            }}
          >
            <App />
          </ConfigProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
