import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Lab, Prescription } from 'models/todo';
import {
  DarkLink,
  InnerSubHeading,
  SubHeading,
} from 'components/typography/Typography';
import { Button } from 'components/forms/Button';
import { ReactComponent as GalileoDocumentIcon } from 'assets/galileo_document_icon.svg';
import { ReactComponent as GalileoLabIcon } from 'assets/galileo_lab_icon.svg';
import { ReactComponent as GalileoMapPinIcon } from 'assets/galileo_map_pin_icon.svg';
import { ReactComponent as GalileoPrescriptionIcon } from 'assets/galileo_prescription_icon.svg';
import { createRef, useEffect, useState } from 'react';
import {
  findAddressLatLong,
  getGoogleMapsUrl,
  getGooglePlaceFromLocationName,
  isGoogleMapsPlacesLibraryLoaded,
} from 'helpers/googleMaps';
import { useTranslation } from 'react-i18next';
import { useHomePageStore } from './store';
import { getQuestRequisitionService } from './services';

export const TaskViewModal = () => {
  const [
    patientLatLong,
    setShowTaskModal,
    labTask,
    setLabTask,
    prescriptionTask,
    setPrescriptionTask,
  ] = useHomePageStore(state => [
    state.patientLatLong,
    state.setShowTaskModal,
    state.labTask,
    state.setLabTask,
    state.prescriptionTask,
    state.setPrescriptionTask,
  ]);

  function onCloseModal() {
    setShowTaskModal(false);
    setLabTask([]);
    setPrescriptionTask([]);
  }

  return (
    <div
      data-testid="taskModal"
      className="modal fixed left-0 top-0 flex h-screen w-full items-start justify-center overflow-y-auto bg-ivory-2 text-center"
    >
      <button
        type="button"
        data-testid="closeTaskModal"
        className="fixed left-8 top-8 h-8 w-8 border-none focus:outline-none"
        onClick={onCloseModal}
      >
        <CloseOutlined className="text-xl" />
      </button>
      <div className="mx-auto my-16 inline-flex flex-col items-start justify-start">
        <div className="flex flex-col items-center justify-start self-stretch bg-white px-px">
          {labTask.length > 0 && (
            <LabTaskView labTask={labTask} patientLatLong={patientLatLong} />
          )}
          {prescriptionTask.length > 0 && (
            <PrescriptionTaskView prescriptionTask={prescriptionTask} />
          )}
        </div>
      </div>
    </div>
  );
};

type LabTaskViewProps = {
  labTask: Lab[] | [];
  patientLatLong: google.maps.LatLngLiteral | null;
};

const LabTaskView = (props: LabTaskViewProps) => {
  const { labTask, patientLatLong } = props;
  const [isQuestMapLoaded, setIsQuestMapLoaded] = useState(false);
  const [questMap, setQuestMap] = useState<google.maps.Map | null>(null);
  const [questMapLink, setQuestMapLink] = useState<string | null>(null);
  const labMapRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (isQuestMapLoaded || !patientLatLong || !labMapRef.current) {
      return;
    }
    buildQuestMap(labMapRef, patientLatLong).then(labMap => {
      if (!labMap) {
        return;
      }
      setQuestMap(labMap);
      setQuestMapLink(
        getGoogleMapsUrl(
          `Quest Diagnostics near ${patientLatLong.lat}, ${patientLatLong.lng}`
        )
      );
      setIsQuestMapLoaded(true);
    });
  }, [isQuestMapLoaded, labMapRef, patientLatLong, questMap]);

  return (
    <div
      className="mx-auto inline-flex w-full max-w-2xl flex-col items-start justify-between rounded-lg border border-ivory-6 bg-white"
      data-testid="labTaskModal"
    >
      <div className="h-32 w-full self-stretch">
        <div ref={labMapRef} className="h-32 w-full" />
      </div>
      <div className="inline-flex flex-col items-start justify-start gap-6 p-6">
        <LabTaskHeader mapUrl={questMapLink} />
        <LabTaskBody labTask={labTask} />
      </div>
    </div>
  );
};

type LabTaskHeaderProps = {
  mapUrl: string | null;
};

const LabTaskHeader = (props: LabTaskHeaderProps) => {
  const { t } = useTranslation('home');
  const { mapUrl } = props;

  const onOpenMap = () => {
    if (mapUrl) {
      window.open(mapUrl, '_blank');
    }
  };
  return (
    <div className="flex w-full flex-col items-start gap-2">
      <SubHeading className="text-start text-3xl text-charcoal-12">
        {t('Complete your lab tests')}
      </SubHeading>
      <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
        {t('Visit any Quest Diagnostics location, no appointment necessary.')}
      </div>
      <Button type="primary" onClick={onOpenMap}>
        {t('Open Map')}
      </Button>
    </div>
  );
};

type LabTaskBodyProps = {
  labTask: Lab[] | [];
};

const LabTaskBody = (props: LabTaskBodyProps) => {
  const { t } = useTranslation('home');
  const { labTask } = props;

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-4">
      <LabDetails labTask={labTask} />
      <div className="inline-flex flex-col items-start justify-start gap-2">
        <InnerSubHeading>{t('What should I bring?')}</InnerSubHeading>
        <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
          {t(
            'Bring your ID, insurance card, and the document below. The entire process takes under 20 minutes.'
          )}
        </div>
      </div>
      <LabDocument labTask={labTask} />
      <div className="inline-flex flex-col items-start justify-start gap-2">
        <InnerSubHeading>{t('What happens next?')}</InnerSubHeading>
        <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
          {t(
            "Once you've completed the lab testing Galileo will recieve the results. Your doctor will share them with you in the app."
          )}
        </div>
      </div>
      <div className="inline-flex flex-col items-start justify-start gap-2">
        <InnerSubHeading>{t('What will it cost?')}</InnerSubHeading>
        <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
          {t(
            'The tests that we order are typically covered by insurance and usually cost less than $100. To get an exact price, call your insurance provider.'
          )}
        </div>
      </div>
    </div>
  );
};

type LabDetailsProps = {
  labTask: Lab[] | [];
};

const LabDetails = (props: LabDetailsProps) => {
  const { t } = useTranslation('home');
  const { labTask } = props;

  return (
    <div className="inline-flex flex-col items-start justify-start gap-0.5">
      <InnerSubHeading>{t('Lab details')}</InnerSubHeading>
      {labTask[0].tests.length > 0 ? (
        labTask[0].tests.map(test => (
          <div
            key={test.test_code}
            className="inline-flex items-start justify-start gap-4 py-4"
          >
            <GalileoLabIcon />
            <div className="flex items-center justify-start">
              <div className="inline-flex flex-col items-start justify-start">
                <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
                  {test.display_name}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          data-testid="noTests"
          className="self-stretch text-left text-base font-normal leading-tight text-zinc-800"
        >
          {t('No tests')}
        </div>
      )}
    </div>
  );
};

type LabDocumentProps = {
  labTask: Lab[] | [];
};

const LabDocument = (props: LabDocumentProps) => {
  const { t } = useTranslation('home');
  const { labTask } = props;
  const [labDocument, setLabDocument] = useState<Blob | null>(null);

  useEffect(() => {
    if (!labTask || labTask.length === 0) {
      return;
    }
    getQuestRequisitionService(
      labTask[0].patient_account_id,
      labTask[0].quest_order_id
    ).then(res => {
      if (res && res.data) {
        setLabDocument(convertBase64ToPdf(res.data[0].pdf_bytes));
      }
    });
  }, [labTask]);

  const onOpenDocument = () => {
    if (labDocument) {
      window.open(URL.createObjectURL(labDocument), '_blank');
    }
  };

  const convertBase64ToPdf = (base64String: string) => {
    const byteCharacters = window.atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf;base64' });
  };

  return (
    <button
      type="button"
      className="inline-flex w-full flex-row items-center justify-start gap-2.5 rounded-lg border border-charcoal-1 px-4 py-4"
      onClick={onOpenDocument}
      onKeyDown={onOpenDocument}
    >
      <GalileoDocumentIcon />
      <div className="inline-flex flex-col items-start justify-start gap-2.5">
        <InnerSubHeading>{t('Test details')}</InnerSubHeading>
        <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
          {t('Show this document to the lab')}
        </div>
      </div>
      <div className="ml-auto inline-flex flex-col items-center justify-center gap-2">
        <RightOutlined />
      </div>
    </button>
  );
};

type PrescriptionTaskViewProps = {
  prescriptionTask: Prescription[];
};

const PrescriptionTaskView = (props: PrescriptionTaskViewProps) => {
  const { prescriptionTask } = props;
  const [isPharmacyMapLoaded, setIsPharmacyMapLoaded] = useState(false);
  const [pharmacyMap, setPharmacyMap] = useState<google.maps.Map | null>(null);
  const [pharmacyLatLong, setPharmacyLatLong] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [pharmacyMapLink, setPharmacyMapLink] = useState<string | null>(null);
  const pharmacyMapRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (isPharmacyMapLoaded) {
      return;
    }

    findAddressLatLong(
      prescriptionTask[0].pharmacy_street ?? '',
      prescriptionTask[0].pharmacy_city ?? '',
      prescriptionTask[0].pharmacy_state ?? '',
      prescriptionTask[0].pharmacy_zip ?? ''
    ).then(res => {
      setPharmacyLatLong(res);
    });

    buildPharmacyMap(pharmacyMapRef, pharmacyLatLong).then(pharmMap => {
      if (!pharmMap) {
        return;
      }
      setPharmacyMap(pharmMap);

      getGooglePlaceFromLocationName(
        pharmMap,
        prescriptionTask[0].pharmacy_store_name
      ).then(place => {
        setPharmacyMapLink(
          getGoogleMapsUrl(`${place?.name}&query_place_id=${place?.place_id}`)
        );
      });

      setIsPharmacyMapLoaded(true);
    });
  }, [
    isPharmacyMapLoaded,
    pharmacyMapRef,
    prescriptionTask,
    pharmacyMap,
    pharmacyLatLong,
  ]);

  return (
    <div
      className="mx-auto inline-flex w-full max-w-2xl flex-col items-start justify-between rounded-lg border border-ivory-6 bg-white"
      data-testid="presciptionTaskModal"
    >
      <div className="h-32 w-full self-stretch">
        <div ref={pharmacyMapRef} className="h-32 w-full" />
      </div>
      <div className="inline-flex flex-col items-start justify-start gap-6 p-6">
        <PrescriptionTaskHeader
          pharmacyName={prescriptionTask[0].pharmacy_store_name}
          mapUrl={pharmacyMapLink}
        />
        <PrescriptionTaskBody prescriptionTask={prescriptionTask} />
      </div>
    </div>
  );
};

type PrescriptionTaskHeaderProps = {
  pharmacyName: string | null;
  mapUrl: string | null;
};

const PrescriptionTaskHeader = (props: PrescriptionTaskHeaderProps) => {
  const { t } = useTranslation('home');
  const { pharmacyName, mapUrl } = props;

  const onOpenMap = () => {
    if (mapUrl) {
      window.open(mapUrl, '_blank');
    }
  };

  return (
    <div className="flex flex-col items-start gap-2">
      <SubHeading className="text-start text-3xl text-charcoal-12">
        {t('Pick up prescriptions')}
      </SubHeading>
      <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
        {t('Your selected pharmacy was {{pharmacyName}}', { pharmacyName })}
      </div>
      <Button type="primary" onClick={onOpenMap}>
        {t('Open Map')}
      </Button>
    </div>
  );
};

type PrescriptionTaskBodyProps = {
  prescriptionTask: Prescription[];
};

const PrescriptionTaskBody = (props: PrescriptionTaskBodyProps) => {
  const { t } = useTranslation('home');
  const { prescriptionTask } = props;

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-4">
      <PrescriptionDetails prescriptions={prescriptionTask} />
      <div className="inline-flex flex-col items-start justify-start gap-2">
        <InnerSubHeading>{t('What happens next?')}</InnerSubHeading>
        <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
          {t(
            'Please take as prescribed. If you have any questions or experience any adverse side effects you can send us a message at any time.'
          )}
        </div>
      </div>
    </div>
  );
};

type PrescriptionDetailsProps = {
  prescriptions: Prescription[] | [];
};

const PrescriptionDetails = (props: PrescriptionDetailsProps) => {
  const { t } = useTranslation('home');
  const { prescriptions } = props;

  const mapLinkQuery = `${prescriptions[0].pharmacy_store_name} ${prescriptions[0].pharmacy_street} ${prescriptions[0].pharmacy_city} ${prescriptions[0].pharmacy_state} ${prescriptions[0].pharmacy_zip}`;
  const mapLink = encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${mapLinkQuery}`
  );

  return (
    <div className="inline-flex flex-col items-start justify-start gap-0.5">
      <InnerSubHeading>{t('Prescription details')}</InnerSubHeading>
      {prescriptions && prescriptions.length > 0 ? (
        prescriptions.map(prescription => (
          <div
            key={prescription.fdb_medid}
            className="inline-flex items-start justify-start gap-4 py-4"
          >
            <GalileoPrescriptionIcon />
            <div className="flex items-center justify-start">
              <div className="inline-flex flex-col items-start justify-start">
                <div className="self-stretch text-left text-base font-normal leading-tight text-zinc-800">
                  {prescription.medication_drug_description}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          data-testid="noPrescriptions"
          className="self-stretch text-left text-base font-normal leading-tight text-zinc-800"
        >
          {t('No prescriptions')}
        </div>
      )}
      <div className="inline-flex items-start justify-start gap-4 py-4">
        <GalileoMapPinIcon />
        <div className="flex items-center justify-start">
          <div className="inline-flex flex-col items-start justify-start text-left">
            <DarkLink href={mapLink}>
              {prescriptions[0].pharmacy_store_name}
              <br />
              {prescriptions[0].pharmacy_street}
              <br />
              {prescriptions[0].pharmacy_city},{' '}
              {prescriptions[0].pharmacy_state} {prescriptions[0].pharmacy_zip}
              <br />
              {formatPhoneNumber(prescriptions[0].pharmacy_phone)}
            </DarkLink>
          </div>
        </div>
      </div>
    </div>
  );
};

function formatPhoneNumber(phoneNumberString: string | null) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

async function buildQuestMap(
  mapRef: React.RefObject<HTMLDivElement>,
  patientLatLong: google.maps.LatLngLiteral | null
) {
  const mapDiv = mapRef.current;

  if (!mapDiv || !patientLatLong || !isGoogleMapsPlacesLibraryLoaded()) {
    return;
  }

  const questMap = new google.maps.Map(mapDiv, {
    mapId: '83c17145dff41f54',
    zoom: 13,
    disableDefaultUI: true,
    clickableIcons: false,
    gestureHandling: 'none',
    zoomControl: false,
    keyboardShortcuts: false,
  });

  const placesService = new google.maps.places.PlacesService(questMap);
  // Find nearest Quest Diagnostics
  await placesService.nearbySearch(
    {
      location: patientLatLong,
      keyword: 'Quest Diagnostics',
      rankBy: google.maps.places.RankBy.DISTANCE,
    },
    (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (!results || results.length === 0) {
          return;
        }
        // Re-center map on nearest result
        if (results[0].geometry && results[0].geometry.location) {
          questMap.setCenter(results[0].geometry.location);
        }
        // Add markers for all results
        for (let i = 0; i < results.length; i += 1) {
          const place = results[i];
          if (place.geometry && place.geometry.location) {
            const marker = new google.maps.marker.AdvancedMarkerElement({
              position: place.geometry.location,
              map: questMap,
            });
            console.debug('Adding marker', marker);
          }
        }
      }
    }
  );

  return questMap;
}

async function buildPharmacyMap(
  mapRef: React.RefObject<HTMLDivElement>,
  pharmacyLatLong: google.maps.LatLngLiteral | null
) {
  const mapDiv = mapRef.current;

  if (!mapDiv || !isGoogleMapsPlacesLibraryLoaded()) {
    return;
  }

  if (!pharmacyLatLong) {
    return;
  }

  const pharmacyMap = new google.maps.Map(mapDiv, {
    mapId: '83c17145dff41f54',
    center: pharmacyLatLong,
    zoom: 15,
    disableDefaultUI: true,
    clickableIcons: false,
    gestureHandling: 'none',
    zoomControl: false,
    keyboardShortcuts: false,
  });

  const marker = new google.maps.marker.AdvancedMarkerElement({
    position: pharmacyLatLong,
    map: pharmacyMap,
  });
  console.debug('Adding marker', marker);

  return pharmacyMap;
}
