/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */

import { CircleBack, CircleSubmit } from 'components/forms';
import { ChangeEventHandler, FormEventHandler, useRef } from 'react';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  Heading,
  Paragraph,
} from 'components';
import { usePatient } from 'providers/OnboardedPatientProvider';
import {
  CameraOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import { usePhotoIdUpload } from './usePhotoIdUpload';
import { useNewUserOnboardingStore } from './store';

export function IdUploadForm() {
  const { t } = useTranslation('onboarding');
  const uploadRef = useRef<HTMLInputElement>(null);

  const [hasInsurance, setCurrentStep] = useNewUserOnboardingStore(state => [
    state.hasInsurance,
    state.setCurrentStep,
  ]);

  const { accountId } = usePatient();
  const { isLoading, isPending, isValid, photoUrl, uploadPhoto, deletePhoto } =
    usePhotoIdUpload(accountId);
  const showSpinner = isLoading || isPending;
  const showPhoto = Boolean(photoUrl);
  const showUpload = !showSpinner && !showPhoto;
  const canDelete = !isLoading && !isPending && showPhoto;
  const canSubmit = !isLoading && !isPending && isValid;

  const onChange: ChangeEventHandler<HTMLInputElement> = event => {
    const file: File | undefined = event.target.files?.[0];
    if (!file) return;
    if (!file.type.includes('image')) return;
    uploadPhoto(file);
  };

  const onSubmit: FormEventHandler = e => {
    e.preventDefault();
    Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_ID_UPLOAD_SCREEN);
    if (hasInsurance) {
      setCurrentStep('INSURANCE_CONFIRMATION');
    } else {
      setCurrentStep('INSURANCE_ENTRY');
    }
  };

  const onBack = () => {
    setCurrentStep('ADDRESS_ENTRY');
  };

  return (
    <form onSubmit={onSubmit}>
      <PageLayout progress={6 / 9}>
        <PageLayoutContent>
          <Heading>{t('Upload Photo ID')}</Heading>
          <Paragraph type="note">
            {t(
              'A government-issued photo ID is needed when providing care. Please upload a photo of your driver’s license or passport.'
            )}
          </Paragraph>

          <section className="relative grid aspect-photo max-h-[24rem] w-full max-w-[24rem] grid-cols-1 items-center justify-center rounded-lg border border-gray-2 bg-gray-1">
            {showSpinner && (
              <div className="absolute inset-0 z-10 grid place-items-center rounded-lg border border-gray-2 bg-gray-1 opacity-75">
                <LoadingOutlined className="text-3xl text-gray-6" />
              </div>
            )}

            {canDelete && (
              <button
                type="button"
                className="z-8 absolute right-2 top-2 h-8 w-8 border-0 bg-space-10 p-0 text-sm text-white hover:border-0"
                onClick={deletePhoto}
              >
                <CloseOutlined />
              </button>
            )}

            {showPhoto && (
              <img src={photoUrl} alt="Photo ID" className="rounded-lg" />
            )}

            {showUpload && (
              <>
                <button
                  type="button"
                  className="cursor-pointer border-0 focus:outline-none"
                  onClick={() => uploadRef.current?.click()}
                >
                  <div className="text-center">
                    <CameraOutlined className="mb-2 block text-3xl text-gray-6" />
                    <span className="text-lg text-gray-6">
                      {t('Upload a photo')}
                    </span>
                  </div>
                </button>

                <input
                  type="file"
                  accept="image/jpeg,image/png"
                  className="hidden"
                  ref={uploadRef}
                  onChange={onChange}
                />
              </>
            )}
          </section>
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit valid={canSubmit} loading={false} />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
