import { Heading } from 'components';
import { ReactComponent as GalileoLogoDark } from 'assets/galileo-logo-dark.svg';
import { ReactComponent as ArcedArrow } from 'assets/arced_arrow.svg';
import { ReactComponent as Oops } from 'assets/oops.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/forms';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const NotFound: FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation();
  return (
    <div className="bg-ivory-2 notfound-layout-mobile md:notfound-layout-desktop">
      <header className="col-span-3 hidden p-4 md:block">
        <GalileoLogoDark className="w-24" />
      </header>
      <section className="grid place-items-center text-center">
        <div>
          <div className="relative grid place-items-center">
            <Oops className="absolute" />
            <Heading className="mb-16 mt-32 text-5xl tracking-tight md:text-7xl">
              {t('Page not found.')}
            </Heading>
            <ArcedArrow className="absolute -bottom-12 right-2 hidden md:block" />
          </div>
          <Button type="primary" onClick={() => navigate('/')}>
            {isAuthenticated ? t('Back to homepage') : t('Log In')}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
