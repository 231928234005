import { cn } from 'lib/util';

type ProgressBarProps = {
  progress?: number;
};

export function ProgressBar(props: ProgressBarProps) {
  const { progress } = props;

  if (!progress) return null;

  const isFull = progress === 1;
  const width = `${progress * 100}%`;

  return (
    <div
      data-testid="onboarding-progress-bar"
      data-progress={progress}
      className="absolute inset-0 h-1.5 bg-charcoal-1 shadow-inner md:rounded-t-md"
    >
      <div
        className={cn(
          'h-full bg-space-10 md:rounded-tl-md',
          isFull && 'md:rounded-tr-md'
        )}
        // We use a style prop since TW JIT doesn't pick up dynamic arbitrary values
        style={{ width }}
      />
    </div>
  );
}
