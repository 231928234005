/* eslint-disable jsx-a11y/label-has-associated-control */

import { Answer } from '@galileo/core-api-client';
import { cn } from 'lib/util';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

type RadioButtonProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  answer: Answer;
  required: boolean;
};

export function Radio<TFieldValues extends FieldValues>(
  props: RadioButtonProps<TFieldValues>
) {
  const { control, name, answer, required } = props;
  const { field } = useController({ name, control, rules: { required } });
  const isSelected = field.value === answer.id;

  return (
    <div>
      <input
        type="radio"
        className="appearance-none"
        id={answer.id}
        name={name}
        value={answer.id}
        checked={isSelected}
        onChange={field.onChange}
      />
      <label
        htmlFor={answer.id}
        className={cn(
          'grid cursor-pointer grid-cols-[min-content_1fr] items-center gap-3 rounded-md border border-charcoal-2 p-4 transition-all duration-150 ease-in-out hover:border-charcoal-4',
          isSelected && 'border-earth-100 bg-earth-100 hover:border-earth-100'
        )}
      >
        <div>
          {isSelected ? (
            <div className="relative grid h-5 w-5 place-items-center rounded-full border border-space-12 bg-white">
              <div className=" h-[14px] w-[14px] rounded-full bg-space-12" />
            </div>
          ) : (
            <div className="relative h-5 w-5 rounded-full border border-charcoal-2" />
          )}
        </div>
        <div>{answer.body}</div>
      </label>
    </div>
  );
}
