import type { ReactElement } from 'react';
import { usePersistQueryParams } from 'hooks/useCachedQueryParams';
import { AuthProvider } from './AuthProvider';
import { AuthRequiredBoundary } from './AuthRequiredBoundary';
import { AxiosAuthProvider } from './AxiosAuthProvider';

type AuthAndApiProviderProps = {
  children: ReactElement;
};

/**
 * Provides Auth0 context, forces successful authentication, and adds access
 * token to Axios clients
 */
export function AuthAndApiProvider(props: AuthAndApiProviderProps) {
  usePersistQueryParams(['accessCode', 'source']);
  const { children } = props;
  return (
    <AuthProvider>
      <AuthRequiredBoundary>
        <AxiosAuthProvider>{children}</AxiosAuthProvider>
      </AuthRequiredBoundary>
    </AuthProvider>
  );
}
