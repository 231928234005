import { cn } from 'lib/util';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';

type CircleSubmitProps = {
  valid: boolean;
  loading: boolean;
};

export function CircleSubmit(props: CircleSubmitProps) {
  const disabled = props.loading || !props.valid;
  const { loading } = props;

  return (
    <button
      className={cn(
        'grid h-16 w-16 appearance-none grid-cols-1 place-items-center rounded-full border-0 p-0',
        disabled ? 'bg-gray-2' : 'bg-space-10'
      )}
      aria-label="Submit"
      type="submit"
      disabled={disabled}
    >
      {loading ? (
        <LoadingOutlined className="h-8 text-xl text-charcoal-4" />
      ) : (
        <ArrowRightOutlined
          className={cn(
            'h-8 text-xl',
            disabled ? 'text-charcoal-4' : 'text-white'
          )}
        />
      )}
    </button>
  );
}
