import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { env } from 'lib/env';
import { FullPageLoading } from './FullPageLoading';

const CALLBACK_TIMEOUT = 5000;

export function AuthCallback() {
  const { logout, loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get('error')) {
      // TODO: Do we want to display an error message? And/or log this somewhere?
      logout({
        logoutParams: {
          returnTo: env.VITE_APP_ORIGIN,
        },
      });
    }
    // Occasionally, the function defined in onRedirectCallback never fires
    // Redirect to login after 5 seconds of being stuck on this page
    const timer = setTimeout(() => {
      loginWithRedirect({
        appState: {
          returnTo: '/',
        },
      });
    }, CALLBACK_TIMEOUT);

    return () => clearTimeout(timer);
  }, [loginWithRedirect, searchParams, logout]);

  return <FullPageLoading />;
}
