import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Heading,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  Paragraph,
} from 'components';
import {
  CircleBack,
  CircleSubmit,
  GenericError,
  TextInput,
} from 'components/forms';
import { useAddressAutoComplete } from 'components/forms/AddressAutoComplete';
import { useMutation } from '@tanstack/react-query';
import { usePatient } from 'providers/OnboardedPatientProvider';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import { useNewUserOnboardingStore } from './store';
import { updateAccountService, updatePatientProfileService } from './services';
import { STATES } from './util.ts';
import { useSplit } from '../../helpers';
import { ACTIVE_SPLITS } from '../../constants';

const schema = z.object({
  street: z
    .string()
    .trim()
    .min(1, { message: i18n.t('is required') }),
  city: z
    .string()
    .trim()
    .min(1, { message: i18n.t('is required') }),
  state: z.enum(STATES),
  zip: z
    .string()
    .trim()
    .regex(/^\d{5}$/)
    .length(5, { message: i18n.t('is required') }),
});
type Schema = z.infer<typeof schema>;

export function AddressEntryForm() {
  const { t } = useTranslation('onboarding');
  const { accountId, patientId } = usePatient();
  const shouldUpdateWithCoreApi = useSplit(
    ACTIVE_SPLITS.HEALTHWEB_PATIENT_ACCOUNTS_CUTOVER_WRITES
  );
  const [
    accountDetails,
    storedAddress,
    phoneConfirmed,
    setAddress,
    setCurrentStep,
  ] = useNewUserOnboardingStore(state => [
    {
      dateOfBirth: state.dateOfBirth,
      firstName: state.firstName,
      lastName: state.lastName,
      preferredName: state.preferredName,
      gender: state.gender,
      genderDisplay: state.genderDisplay,
      genderIdentity: state.genderIdentity,
      sexAssignedAtBirth: state.sexAssignedAtBirth,
    },
    {
      street: state.street,
      city: state.city,
      state: state.state,
      zip: state.zip,
    },
    state.phoneConfirmed,
    state.setAddress,
    state.setCurrentStep,
  ]);

  const { control, handleSubmit, formState, setValue, setError } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: storedAddress,
    });

  const inputRef = useAddressAutoComplete({
    isAddressAutoComplete: true,
    onPlaceSelected: place => {
      if (place.street) {
        setValue('street', place.street, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (place.city) {
        setValue('city', place.city, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (place.state) {
        setValue('state', place.state, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (place.zip) {
        setValue('zip', place.zip, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
  });

  const query = useMutation({
    mutationFn: (data: Schema) => {
      setAddress(data);
      if (shouldUpdateWithCoreApi === true) {
        return updatePatientProfileService(patientId, {
          ...accountDetails,
          ...data,
        });
      }
      return updateAccountService(accountId, {
        ...accountDetails,
        ...data,
      });
    },
    onSuccess: () => {
      Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_ADDRESS_SCREEN);
      if (phoneConfirmed) setCurrentStep('ID_UPLOAD');
      else setCurrentStep('PHONE_NUMBER_ENTRY');
    },
    onError: () => {
      setError('root.serverError', {
        message: t('Unable to update information. Please try again.'),
      });
    },
  });

  const onBack = () => {
    setCurrentStep('BIOLOGICAL_SEXES_AND_DOB');
  };

  return (
    <form onSubmit={handleSubmit(data => query.mutate(data))}>
      <PageLayout progress={5 / 9}>
        <PageLayoutContent>
          <Heading>{t("What's your address?")}</Heading>
          <Paragraph type="note">
            {t(
              'This helps us schedule you with the right provider and make more convenient referrals.'
            )}
          </Paragraph>

          <GenericError message={formState.errors.root?.serverError?.message} />

          <TextInput
            name="street"
            label={t('Street address')}
            control={control}
            inputRef={inputRef}
            placeholder=""
            required
          />

          <TextInput name="city" label={t('City')} control={control} required />

          <TextInput
            name="state"
            label={t('State')}
            control={control}
            maxLength={2}
            required
          />

          <TextInput
            name="zip"
            label={t('Zip code')}
            control={control}
            maxLength={5}
            required
          />
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit valid={formState.isValid} loading={query.isLoading} />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
