/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { env } from './env';

const DATADOG_LOGGING_ENVIRONMENTS = ['production', 'staging'];

type LogContext = Record<string, unknown>;

/**
 * Sets up Datadog logging (in `production` or `staging`)
 */
export function initLogging() {
  if (!DATADOG_LOGGING_ENVIRONMENTS.includes(env.VITE_ENVIRONMENT)) return;

  datadogLogs.init({
    clientToken: env.VITE_DATADOG_TOKEN,
    env: env.VITE_ENVIRONMENT,
    forwardErrorsToLogs: true,
    service: 'healthweb',
    site: 'datadoghq.com',
    beforeSend: event => {
      // Ignore ResizeObserver errors
      // https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#discard-a-frontend-error
      if (
        event.type === 'error' &&
        typeof event.error?.message === 'string' &&
        event.error.message.includes('ResizeObserver')
      ) {
        return false;
      }

      return true;
    },
  });
}

function send(
  level: 'info' | 'warn' | 'error',
  message: string,
  context?: LogContext
) {
  // Use console for logging in development
  const logger = DATADOG_LOGGING_ENVIRONMENTS.includes(env.VITE_ENVIRONMENT)
    ? datadogLogs.logger
    : console;

  // Don't send context unless we have it to avoid `undefined` showing up
  if (context) {
    logger[level](message, context);
  } else {
    logger[level](message);
  }
}
export const log = {
  /**
   * Logs an info message and optional context
   * @param message - The message to log
   * @param context - An optional object containing keys and values
   */
  info: (message: string, context?: LogContext) =>
    send('info', message, context),

  /**
   * Logs a warning message and optional context
   * @param message - The message to log
   * @param context - An optional object containing keys and values
   */
  warn: (message: string, context?: LogContext) =>
    send('warn', message, context),

  /**
   * Logs an error message and optional context
   * @param message - The message to log
   * @param context - An optional object containing keys and values
   */
  error: (message: string, context?: LogContext) =>
    send('error', message, context),
};
