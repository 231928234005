import { Heading, SubHeading } from 'components';
import {
  VideoVisitLayout,
  VideoVisitLayoutContent,
} from 'components/pageLayout/VideoVisitLayout';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { FC, useState } from 'react';
import { ReactComponent as Clipboard } from 'assets/Clipboard.svg';
import { Button } from 'antd';
import { VideoConsultation } from '@galileo/core-api-client';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInterval } from '../../helpers/useInterval';
import { getVideoConsultationsService } from './services';

const POLLING_INTERVAL = 5000;

export const VideoVisitWaiting: FC = () => {
  const { t } = useTranslation('home');
  const navigate = useNavigate();
  const { patientAccount } = usePatient();
  const [consultation, setConsultation] = useState<VideoConsultation | null>(
    null
  );

  const getVideoVisitConsultation = async () => {
    if (!patientAccount) return;
    try {
      const response = await getVideoConsultationsService(
        patientAccount.account_id
      );
      if (!response.consultation_id) {
        // TODO: Log to datadog
        console.log('No consultation yet');
        return;
      }

      // The consultation is active (unexpired, incomplete).
      // The patient can now start the video visit. Set the
      // consultation and clear the interval to stop polling
      if (
        new Date(response.expires_at) > new Date() &&
        !response.is_completed
      ) {
        setConsultation(response);
        intervalRefId.clear();
      }
    } catch (e) {
      console.log('Failed to get consultations', e);
    }
  };
  const intervalRefId = useInterval(
    getVideoVisitConsultation,
    POLLING_INTERVAL,
    true
  );

  return (
    <VideoVisitLayout joinVideoVisit={Boolean(consultation)}>
      <VideoVisitLayoutContent>
        {!consultation ? (
          <>
            <SubHeading>
              {t('Welcome, {{name}}', {
                name:
                  patientAccount.preferred_name ??
                  patientAccount.first_name ??
                  '',
              })}
            </SubHeading>
            <section
              data-testid="videoVisitWaiting"
              className="flex flex-col items-center text-center"
            >
              <Heading>{t('Your appointment is starting soon...')}</Heading>
              <div className="mx-auto my-5 w-52 max-w-md space-y-2 pt-8 sm:flex sm:flex-col sm:items-center sm:justify-center sm:space-y-0 sm:pt-4">
                <Clipboard className="mx-auto block h-20 rounded-full sm:mx-0 sm:shrink-0" />
              </div>
              <div className="my-5 max-w-xs items-center text-center">
                <p>
                  {t(
                    'Stay tuned. Our doctors are reviewing your records and will join momentarily.'
                  )}
                </p>
              </div>
            </section>
          </>
        ) : (
          <section
            data-testid="videoVisitJoin"
            className="flex flex-col items-center text-center"
          >
            <Heading>
              {t("It's time! Join your video visit, {{name}}.", {
                name:
                  patientAccount.preferred_name ??
                  patientAccount.first_name ??
                  '',
              })}
            </Heading>
            <div className="mx-auto my-5 w-52 max-w-md space-y-2 pt-8 sm:flex sm:flex-col sm:items-center sm:justify-center sm:space-y-0 sm:pt-4">
              <Button
                data-testid="joinButton"
                className="rounded-full bg-gold-10"
                onClick={() => {
                  navigate(
                    `/video-visit?meetingNumber=${consultation.zoom_meeting_id}&password=${consultation.zoom_meeting_password}`
                  );
                }}
              >
                {t('Join Now')}
              </Button>
            </div>
          </section>
        )}
      </VideoVisitLayoutContent>
    </VideoVisitLayout>
  );
};
