import { env } from 'lib/env';
import mixpanel, { Dict } from 'mixpanel-browser';

const isLocal = env.VITE_ENVIRONMENT === 'development';

mixpanel.init(env.VITE_MIXPANEL_TOKEN, {
  persistence: 'localStorage',
});

const actions = {
  identify: (id: string) => {
    if (isLocal) return;
    mixpanel.identify(id);
  },
  track: (event: string, props?: Dict) => {
    if (isLocal) return;
    mixpanel.track(event, props);
  },
  people: {
    set: (props: Dict) => {
      if (isLocal) return;
      mixpanel.people.set(props);
    },
  },
};

const events = {
  FTUX_SKIPPED_EMPLOYEE_SPONSORSHIP_SCREEN:
    'ftuxSkippedEmployeeSponsorshipScreen',
  FTUX_STARTED_POST_AUTH0: 'ftuxStartedPostAuth0',
  FTUX_STARTED_PRE_AUTH0: 'ftuxStartedPreAuth0',
  FTUX_SUBMITTED_ADDRESS_SCREEN: 'ftuxSubmittedAddressScreen',
  FTUX_SUBMITTED_DOB_GENDER_SCREEN: 'ftuxSubmittedDobGenderScreen',
  FTUX_SUBMITTED_EMPLOYEE_SPONSORSHIP_SCREEN:
    'ftuxSubmittedEmployeeSponsorshipScreen',
  FTUX_SUBMITTED_ID_UPLOAD_SCREEN: 'ftuxSubmittedIdUploadScreen',
  FTUX_SUBMITTED_INSURANCE_INFO_SCREEN: 'ftuxSubmittedInsuranceInfoScreen',
  FTUX_SUBMITTED_NAME_SCREEN: 'ftuxSubmittedNameScreen',
  FTUX_SUBMITTED_PHONE_NUMBER_SCREEN: 'ftuxSubmittedPhoneNumberScreen',
  FTUX_SUBMITTED_PHONE_NUMBER_CONFIRMATION_SCREEN:
    'ftuxSubmittedPhoneNumberConfirmationScreen',
  VIEW_AUTH0_UNIVERSAL_LOGIN: 'viewAuth0UniversalLogin',
};

export const Mixpanel = actions;
export const MixpanelEvents = events;
