import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { Checkbox as AntCheckbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';

type CheckboxProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  children: React.ReactNode;
  className?: string;
  onChange?: (e: CheckboxChangeEvent) => void;
};

export function Checkbox<TFieldValues extends FieldValues>(
  props: CheckboxProps<TFieldValues>
) {
  const { control, name, children, className, onChange } = props;
  const { field } = useController({ name, control });

  // TODO: Use fieldState for validation styling
  const onChangeHandler = (e: CheckboxChangeEvent) => {
    field.onChange(e.target.checked);
  };

  return (
    <fieldset
      className={`grid grid-cols-[min-content_1fr] items-center gap-4 ${
        (className && className) || ''
      }`}
    >
      <AntCheckbox
        id={name}
        name={name}
        onChange={onChange || onChangeHandler}
        checked={field.value}
      />
      <label htmlFor={name}>{children}</label>
    </fieldset>
  );
}
