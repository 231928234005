/**
 * Custom useInterval hook
 * https://github.com/Hermanya/use-interval
 */

import { useEffect, useRef } from 'react';

/** keep typescript happy */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export function useInterval(
  callback: () => void,
  delay: number | null | false,
  immediate?: boolean
) {
  const savedCallback = useRef(noop);
  const intervalIdRef = useRef<number | undefined>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Execute callback if immediate is set.
  useEffect(() => {
    if (!immediate) return;
    if (delay === null || delay === false) return;
    savedCallback.current();
  }, [delay, immediate]);

  // Set up the interval.
  useEffect(() => {
    if (delay === null || delay === false) return undefined;
    const tick = () => savedCallback.current();
    intervalIdRef.current = window.setInterval(tick, delay);
    return () => clearInterval(intervalIdRef.current);
  }, [delay]);

  const clear = () => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = undefined;
  };

  return { clear };
}

export default useInterval;
