import { CircleBack, CircleSubmit, Radio, TextInput } from 'components/forms';
import { z } from 'zod';
import {
  Heading,
  PageLayout,
  PageLayoutContent,
  PageLayoutFooter,
  SubHeading,
} from 'components';
import { useForm, Control, UseFormWatch } from 'react-hook-form';
import { Space } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { format } from 'date-fns';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MixpanelEvents } from 'helpers/mixpanel';
import {
  GENDER_IDENTITY,
  GenderIdentity,
  SexAssignedAtBirth,
  SEX_ASSIGNED_AT_BIRTH,
} from './constants';
import { useNewUserOnboardingStore } from './store';

const validateDateOfBirth = (date: string) => {
  // Validate date of birth is in the format MM/DD/YYYY or MM-DD-YYYY
  // eslint-disable-next-line no-useless-escape
  const dateOfBirthRegex = /^\d{1,2}[-\/.\ ]\d{1,2}[-\/.\ ]\d{4}$/;
  if (!dateOfBirthRegex.test(date)) {
    return false;
  }

  const parsedDate = new Date(date);
  return !Number.isNaN(parsedDate.getTime());
};

const schema = z.object({
  dateOfBirth: z.string().refine(validateDateOfBirth, {
    message: i18n.t('Invalid Date of Birth', { ns: 'onboarding' }),
  }),
  sexAssignedAtBirth: z.enum(SEX_ASSIGNED_AT_BIRTH),
  genderIdentity: z.enum(GENDER_IDENTITY).optional(),
  otherGenderIdentity: z.string().optional(),
});

type Schema = z.infer<typeof schema>;

type GenderIdentityProps = {
  control: Control<Schema>;
  watch: UseFormWatch<Schema>;
};

const GenderIdentityControl = (props: GenderIdentityProps) => {
  const { t } = useTranslation('onboarding');
  const { control, watch } = props;
  const genderIdentityOptions: {
    key: string;
    label: string;
    value: GenderIdentity;
  }[] = [
    {
      key: '0',
      label: i18n.t('Female/Woman', { ns: 'onboarding' }),
      value: 'Female/Woman',
    },
    {
      key: '1',
      label: i18n.t('Male/Man', { ns: 'onboarding' }),
      value: 'Male/Man',
    },
    {
      key: '2',
      label: i18n.t('Trans Female/Trans Woman', { ns: 'onboarding' }),
      value: 'Trans Female/Trans Woman',
    },
    {
      key: '3',
      label: i18n.t('Trans Male/Trans Man', { ns: 'onboarding' }),
      value: 'Trans Male/Trans Man',
    },
    {
      key: '4',
      label: i18n.t('Genderqueer', { ns: 'onboarding' }),
      value: 'Genderqueer',
    },
    {
      key: '5',
      label: i18n.t('Nonbinary', { ns: 'onboarding' }),
      value: 'Nonbinary',
    },
    {
      key: '6',
      label: i18n.t('Two-Spirit', { ns: 'onboarding' }),
      value: 'Two-Spirit',
    },
    {
      key: '7',
      label: i18n.t('Agender', { ns: 'onboarding' }),
      value: 'Agender',
    },
    {
      key: '8',
      label: i18n.t('Prefer Not to Say', { ns: 'onboarding' }),
      value: 'Prefer Not to Say',
    },
    {
      key: '9',
      label: i18n.t('Other', { ns: 'onboarding' }),
      value: 'Other',
    },
  ];

  const genderIdentity = watch('genderIdentity');

  return (
    <div className="w-full">
      <Space direction="vertical">
        <div className="text-color-charcoal-12 text-base">
          {t('Select your gender identity')}
        </div>
        <Radio
          control={control}
          name="genderIdentity"
          options={genderIdentityOptions}
          direction="horizontal"
        />
        {genderIdentity === 'Other' && (
          <TextInput
            control={control}
            name="otherGenderIdentity"
            label={t('Write in Gender Identity')}
            type="text"
          />
        )}
      </Space>
    </div>
  );
};

type SexAssignedAtBirthProps = {
  control: Control<Schema>;
};

const SexAssignedAtBirthControl = (props: SexAssignedAtBirthProps) => {
  const { t } = useTranslation('onboarding');
  const { control } = props;

  const sexAssignedAtBirthOptions: {
    key: string;
    label: string;
    value: SexAssignedAtBirth;
  }[] = [
    {
      key: '0',
      label: i18n.t('Male', { ns: 'onboarding' }),
      value: 'male',
    },
    {
      key: '1',
      label: i18n.t('Female', { ns: 'onboarding' }),
      value: 'female',
    },
    {
      key: '2',
      label: i18n.t('Intersex', { ns: 'onboarding' }),
      value: 'intersex',
    },
  ];

  return (
    <div className="mb-4">
      <div className="text-base">
        {t('For insurance purposes, what is your sex assigned at birth?')}
      </div>
      <div className="mt-2">
        <Radio
          control={control}
          name="sexAssignedAtBirth"
          options={sexAssignedAtBirthOptions}
          direction="horizontal"
        />
      </div>
    </div>
  );
};

export function BiologicalSexesForm() {
  const { t } = useTranslation('onboarding');
  const [
    setBiologicalSexesAndDOB,
    setCurrentStep,
    storedName,
    storedDateOfBirth,
    storedGender,
  ] = useNewUserOnboardingStore(state => [
    state.setBiologicalSexesAndDOB,
    state.setCurrentStep,
    {
      preferredName: state.preferredName,
      firstName: state.firstName,
    },
    state.dateOfBirth,
    {
      sexAssignedAtBirth: state.sexAssignedAtBirth,
      genderIdentity: state.genderIdentity,
      otherGenderIdentity: state.otherGenderIdentity,
    },
  ]);
  const displayName = storedName.preferredName || storedName.firstName;

  const [showGenderIdentity, setShowGenderIdentity] = useState(
    Boolean(storedGender.genderIdentity || storedGender.otherGenderIdentity)
  );

  const { control, handleSubmit, formState, watch } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      dateOfBirth: storedDateOfBirth
        ? format(storedDateOfBirth, 'MM/dd/yyyy')
        : '',
      sexAssignedAtBirth: storedGender.sexAssignedAtBirth,
      genderIdentity: storedGender.genderIdentity,
      otherGenderIdentity: storedGender.otherGenderIdentity,
    },
  });

  const onSubmit = handleSubmit(data => {
    Mixpanel.track(MixpanelEvents.FTUX_SUBMITTED_DOB_GENDER_SCREEN);

    setBiologicalSexesAndDOB({
      dateOfBirth: data.dateOfBirth,
      sexAssignedAtBirth: data.sexAssignedAtBirth,
      genderIdentity: data.genderIdentity,
      otherGenderIdentity: data.otherGenderIdentity,
    });
    setCurrentStep('ADDRESS_ENTRY');
  });

  const onBack = () => {
    setCurrentStep('NAME_ENTRY');
  };

  return (
    <form onSubmit={onSubmit}>
      <PageLayout progress={2 / 9}>
        <PageLayoutContent>
          <div>
            <SubHeading className="mb-2 text-start text-charcoal-8">
              {t('Welcome, {{name}}!', { name: displayName })}
            </SubHeading>
            <Heading>{t("Let's get started.")}</Heading>
            <Space direction="vertical">
              <div className="-mb-2">
                <TextInput
                  data-testid="dateOfBirth"
                  control={control}
                  required
                  name="dateOfBirth"
                  label={t('Date of Birth (MM/DD/YYYY)')}
                  autoComplete="b-day"
                />
              </div>
              <SexAssignedAtBirthControl control={control} />
            </Space>
            {!showGenderIdentity && (
              <button
                onClick={() => setShowGenderIdentity(true)}
                className="mb-4 border-none p-0 outline-none"
                type="button"
                aria-label="Add Gender Identity"
              >
                + {t('Add Gender Identity')}
              </button>
            )}
            {showGenderIdentity && (
              <GenderIdentityControl control={control} watch={watch} />
            )}
          </div>
        </PageLayoutContent>

        <PageLayoutFooter>
          <CircleBack onClick={onBack} />
          <CircleSubmit
            valid={formState.isValid}
            loading={formState.isSubmitting}
          />
        </PageLayoutFooter>
      </PageLayout>
    </form>
  );
}
