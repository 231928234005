/* eslint-disable react-refresh/only-export-components */
import { SplitClient, withSplitFactory } from '@splitsoftware/splitio-react';
import './App.css';
import { FC } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import {
  useGoogleMapsGeocodingService,
  useGoogleMapsMapsLibrary,
  useGoogleMapsPlacesLibrary,
} from 'helpers/googleMaps';
import { AuthAndApiProvider } from 'providers/AuthAndApiProvider';
import { OnboardedPatientProvider } from 'providers/OnboardedPatientProvider';
import { FullPageLoading } from 'components';
import { PostVideoVisitPage } from 'features/pg-PostVideoVisitPage';
import { IntakesSearchPage } from 'features/pg-IntakesSearch';
import SchedulingPage from 'features/pg-SchedulingPage/SchedulingPage';
import { IntakesPage } from 'features/pg-Intakes';
import { IntakesConfirmationPage } from 'features/pg-Intakes/IntakesConfirmationPage';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthCallback } from 'components/global/AuthCallback';
import { SPLIT_CONFIG, SPLIT_DEFAULT_KEY } from './constants';
import { HomePage } from './features/pg-homePage';
import { NotFound } from './features/pg-notFound';
import { VideoVisitPage } from './features/pg-videoVisitPage';

const App: FC = () => {
  const { isLoading } = useAuth0();
  useGoogleMapsGeocodingService();
  useGoogleMapsMapsLibrary();
  useGoogleMapsPlacesLibrary();

  if (isLoading) {
    return <FullPageLoading />;
  }
  return (
    <SplitClient splitKey={SPLIT_DEFAULT_KEY}>
      <AppRoutes />
    </SplitClient>
  );
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/callback" element={<AuthCallback />} />
      <Route path="*" element={<NotFound />} />

      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/video-visit" element={<VideoVisitPage />} />
        <Route path="/thank-you" element={<PostVideoVisitPage />} />
        <Route path="/intakes/search" element={<IntakesSearchPage />} />
        <Route path="/intakes/:formId" element={<IntakesPage />} />
        <Route
          path="/intakes/confirmation/:formId"
          element={<IntakesConfirmationPage />}
        />
        <Route path="/schedule" element={<SchedulingPage />} />
      </Route>
    </Routes>
  );
}

function ProtectedRoute() {
  return (
    <AuthAndApiProvider>
      <OnboardedPatientProvider>
        <Outlet />
      </OnboardedPatientProvider>
    </AuthAndApiProvider>
  );
}

const initialSplitConfig = {
  ...SPLIT_CONFIG,
  core: {
    ...SPLIT_CONFIG.core,
    key: SPLIT_DEFAULT_KEY,
  },
};

export default withSplitFactory(initialSplitConfig)(App);
