export interface Todo {
  todo_id: string;
  todo_type: TodoType;
  priority: number | null;
  patient_account_id: string;
  operator_account_id: string;
  task_id: string | null;
  case_id: string | null;
  object_id: string | null;
  todo_params: TodoParams | null;
  todo_status: string;
  added_at: string;
  updated_at: string;
  case_name: string | null;
  order_details: Prescription[] | Lab[];
}

export enum TodoType {
  Prescription = 'pickup_prescription',
  Lab = 'complete_lab_testing',
}

export interface TodoParams {
  orders: Order[] | null;
}

export interface Order {
  id: string;
}

export interface Prescription {
  patient_id: string;
  provider_galileo_id: string | null;
  careplan_id: string | null;
  case_id: string | null;
  surescripts_erx_id: string;
  pharmacy_id: string | null;
  pharmacy_ncpdpid: string | null;
  pharmacy_npi: string | null;
  pharmacy_store_name: string | null;
  pharmacy_communication_list: Communication[] | null;
  prescriber_npi: string | null;
  prescriber_spi: string | null;
  prescriber_first_name: string | null;
  prescriber_last_name: string | null;
  prescriber_address_line_1: string | null;
  prescriber_city: string | null;
  prescriber_state: string | null;
  prescriber_zip_code: string | null;
  prescriber_communication_list: Communication[] | null;
  patient_first_name: string | null;
  patient_last_name: string | null;
  patient_gender: string | null;
  patient_date_of_birth: string | null;
  patient_primary_phone: string | null;
  patient_address_line_1: string | null;
  patient_city: string | null;
  patient_state: string | null;
  patient_zip_code: string | null;
  medication_drug_description: string | null;
  medication_ndc: string | null;
  medication_erx_qty: number | null;
  medication_dosage_quantity: number | null;
  medication_quantity_value: number | null;
  medication_quantity_code_list_qualifier: string | null;
  medication_quantity_unit_source_code: string | null;
  medication_clnqty_desc: string | null;
  medication_quantity_potency_unit_code: string | null;
  medication_unit_of_measure_desc: string | null;
  medication_directions: string | null;
  medication_dosage_amount_value: number | null;
  medication_dosage_amount_uom: string | null;
  medication_refills_value: number | null;
  medication_refills_qualifier: string | null;
  medication_daw: number | null;
  medication_days_supply: number | null;
  medication_written_date: string | null;
  medication_note: string | null;
  fdb_med_name_id: number | null;
  fdb_medid: number | null;
  fdb_drug_id: string | null;
  fdb_disp_order_id: number | null;
  is_shared: number;
  is_cancelable: number | null;
  supervisor_id: null | string;
  version: string;
  status: string | null;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  pharmacy_street: string | null;
  pharmacy_city: string | null;
  pharmacy_zip: string | null;
  pharmacy_state: string | null;
  pharmacy_phone: string | null;
  pharmacy_latitude: number | null;
  pharmacy_longitude: number | null;
}

export interface Communication {
  Number: string;
  Qualifier: CommunicationQualifier;
}

export enum CommunicationQualifier {
  Telephone = 'TE',
  Fax = 'FX',
}

export interface Lab {
  quest_order_id: string;
  patient_account_id: string;
  provider_account_id: string;
  case_id: string | null;
  careplan_id: string | null;
  billing_type: string;
  report_comment: string;
  tests: Test[];
}

export interface Test {
  aoe: string[] | null;
  description: string | null;
  display_description: string | null;
  display_name: string | null;
  icd_10_codes: string[] | null;
  test_code: string;
  test_name: string;
}
