import { API, flaskApiClient } from 'constants/apiEndpoints';
import { format } from 'date-fns';
import { Account } from 'models';
import { z } from 'zod';
import { InsuranceCarrier } from 'models/insurance';
import { client } from 'constants/apiV2Clients';
import { Patient } from '@galileo/core-api-client';
import { FLASK_API_GENDER } from './constants';
import { getCoreApiSexAssignedAtBirth, STATES } from './util.ts';

const UpdateAccountParamsSchema = z.object({
  dateOfBirth: z.date(),
  street: z.string(),
  city: z.string(),
  zip: z.string().max(5),
  state: z.enum(STATES),
  firstName: z.string(),
  lastName: z.string(),
  preferredName: z.string().optional(),
  gender: z.enum(FLASK_API_GENDER),
  genderDisplay: z.string().optional(),
  genderIdentity: z.string().optional(),
  sexAssignedAtBirth: z.string(),
  registrationPageSource: z.string().optional(),
});
type UpdateAccountParams = z.infer<typeof UpdateAccountParamsSchema>;

export async function updateAccountService(
  accountId: string,
  account: Partial<UpdateAccountParams>
) {
  const params = UpdateAccountParamsSchema.parse(account);
  await flaskApiClient.put<{ data: Account[] }>(
    API.accountRegistrationComplete(accountId),
    {
      date_of_birth: format(params.dateOfBirth, 'yyyy-MM-dd'),
      address_street: params.street,
      address_city: params.city,
      address_zip: params.zip,
      address_state: params.state,
      first_name: params.firstName,
      last_name: params.lastName,
      preferred_name: params.preferredName,
      gender: params.gender,
      gender_identity: params.genderIdentity,
      gender_display: params.genderDisplay,
      sex_assigned_at_birth: params.sexAssignedAtBirth,
      registration_page_source: params.registrationPageSource,
    }
  );
}

export async function updateRegistrationSourceService(
  patientId: string,
  registrationPageSource: string
) {
  await client.patientsApi.patientsProfileUpdate(patientId, {
    registration_page_source: registrationPageSource,
  });
}

export async function updatePatientProfileService(
  patientId: string,
  account: Partial<UpdateAccountParams>
) {
  const params = UpdateAccountParamsSchema.parse(account);
  await client.patientsApi.patientsProfileUpdate(patientId, {
    date_of_birth: format(params.dateOfBirth, 'yyyy-MM-dd'),
    address: {
      lines: [params.street],
      city: params.city,
      state: params.state,
      zip_code: params.zip,
    },
    first_name: params.firstName,
    last_name: params.lastName,
    preferred_name: params.preferredName,
    gender_identity: params.genderIdentity,
    sex_assigned_at_birth: getCoreApiSexAssignedAtBirth(
      params.sexAssignedAtBirth
    ),
  });
}

export async function searchPatientService(
  accountId: string
): Promise<Patient[]> {
  const response = await client.patientsApi.patientsProfileList(accountId);
  return response.data;
}

type RequestPhoneConfirmationParams = {
  accountId: string;
  countryCode: string;
  phoneNumber: string;
};

export async function requestPhoneConfirmationService(
  params: RequestPhoneConfirmationParams
) {
  const { accountId, countryCode, phoneNumber } = params;
  await flaskApiClient.post(API.accountPhoneVerificationRequest(accountId), {
    mobile_country_code: countryCode,
    mobile_phone_number: phoneNumber,
  });
}

type ConfirmPhoneParams = {
  accountId: string;
  confirmation: string;
};

export async function confirmPhoneService(params: ConfirmPhoneParams) {
  const { accountId, confirmation } = params;
  await flaskApiClient.post(
    API.accountPhoneVerificationConfirmation(accountId),
    {
      mobile_phone_number_verification_code: confirmation,
    }
  );
}

type EmployeeSponsorshipParams = {
  accountId: string;
  accessCode: string;
};

export async function confirmEmployeeSponsorshipService(
  params: EmployeeSponsorshipParams
) {
  const { accountId, accessCode } = params;
  const response = await flaskApiClient.post(
    API.accountEmployeeSponsorshipVerification(accountId),
    {
      access_code: accessCode,
    }
  );
  return response.data.data[0];
}

export async function getInsuranceCarriers() {
  const response = await flaskApiClient.get<{ data: InsuranceCarrier[] }>(
    API.referenceInsuranceCarriers()
  );
  return response.data.data;
}

type EligibilityClaimParams = {
  accountId: string;
  groupId: string;
  memberId: string;
};

type EligibilityClaimResponse = {
  data: {
    eligible: boolean;
    refund: number;
    coupon_claim_method: string;
  }[];
};

export async function claimEligibilityService(params: EligibilityClaimParams) {
  const { accountId, groupId, memberId } = params;
  const response = await flaskApiClient.post<EligibilityClaimResponse>(
    API.accountEligibilityClaim(accountId, groupId),
    { member_id: memberId }
  );
  return response.data.data[0];
}

export async function sendDownloadLinkService(
  countryCode: string,
  phoneNumber: string,
  language: string
) {
  const toNumber = `+${countryCode}${phoneNumber}`;
  await flaskApiClient.post(API.sendDownloadLink(), {
    to_number: toNumber,
    message_key: 'download',
    source: 'healthweb',
    language,
  });
}
