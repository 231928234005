/* eslint-disable jsx-a11y/label-has-associated-control */

import { CheckOutlined } from '@ant-design/icons';
import { Answer } from '@galileo/core-api-client';
import { cn } from 'lib/util';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

type CheckboxProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  answer: Answer;
  required: boolean;
};

export function Checkbox<TFieldValues extends FieldValues>(
  props: CheckboxProps<TFieldValues>
) {
  const { control, name, answer, required } = props;
  const { field } = useController({ name, control, rules: { required } });
  const isSelected = field.value.includes(answer.id);
  const handleChange = () => {
    // NOTE: react-hook-form "should" just handle checkbox arrays, but it's not, so fake it
    if (isSelected) {
      field.onChange(field.value.filter((v: string) => v !== answer.id));
    } else {
      field.onChange([...field.value, answer.id]);
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        className="appearance-none"
        id={answer.id}
        name={name}
        value={answer.id}
        checked={isSelected}
        onChange={handleChange}
      />
      <label
        htmlFor={answer.id}
        className={cn(
          'grid cursor-pointer grid-cols-[min-content_1fr] items-center gap-3 rounded-md border border-charcoal-2 p-4 transition-all duration-150 ease-in-out hover:border-charcoal-4',
          isSelected && 'border-earth-100 bg-earth-100 hover:border-earth-100'
        )}
      >
        <div>
          {isSelected ? (
            <div className="relative grid h-5 w-5 place-items-center rounded bg-space-12">
              <CheckOutlined className="h-3 w-3 text-white" />
            </div>
          ) : (
            <div className="relative h-5 w-5 rounded border border-charcoal-2" />
          )}
        </div>
        <div>{answer.body}</div>
      </label>
    </div>
  );
}
