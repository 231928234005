import { Loader } from '@googlemaps/js-api-loader';
import { env } from '../lib/env';

let googleMapsApiLoader: Loader;
let googleMapsGeocodingService: google.maps.Geocoder;
let googleMapsMapsLibrary: google.maps.MapsLibrary;
let googleMapsPlacesLibrary: google.maps.PlacesLibrary;

function getGoogleMapsApiLoader() {
  if (googleMapsApiLoader !== undefined) {
    return googleMapsApiLoader;
  }

  googleMapsApiLoader = new Loader({
    apiKey: env.VITE_GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['geocoding', 'maps', 'marker', 'places'],
  });

  return googleMapsApiLoader;
}

export function useGoogleMapsGeocodingService() {
  if (googleMapsGeocodingService !== undefined) {
    return googleMapsGeocodingService;
  }

  googleMapsApiLoader = getGoogleMapsApiLoader();
  googleMapsApiLoader.importLibrary('geocoding').then(() => {
    googleMapsGeocodingService = new google.maps.Geocoder();
  });

  return googleMapsGeocodingService;
}

export function isGoogleMapsGeocodingServiceLoaded() {
  return googleMapsGeocodingService !== undefined;
}

export function useGoogleMapsMapsLibrary() {
  if (googleMapsMapsLibrary !== undefined) {
    return googleMapsMapsLibrary;
  }

  googleMapsApiLoader = getGoogleMapsApiLoader();
  googleMapsApiLoader.importLibrary('maps').then(() => {
    googleMapsMapsLibrary = google.maps;
  });

  return googleMapsMapsLibrary;
}

export function isGoogleMapsMapsLibraryLoaded() {
  return googleMapsMapsLibrary !== undefined;
}

export function useGoogleMapsPlacesLibrary() {
  if (googleMapsPlacesLibrary !== undefined) {
    return googleMapsPlacesLibrary;
  }

  googleMapsApiLoader = getGoogleMapsApiLoader();
  googleMapsApiLoader.importLibrary('places').then(() => {
    googleMapsPlacesLibrary = google.maps.places;
  });

  return googleMapsPlacesLibrary;
}

export function isGoogleMapsPlacesLibraryLoaded() {
  return googleMapsPlacesLibrary !== undefined;
}

export const findAddressLatLong = async (
  street: string,
  city: string,
  state: string,
  zip: string
) => {
  if (!isGoogleMapsGeocodingServiceLoaded()) {
    return null;
  }
  const address = `${street}, ${city}, ${state}, ${zip}`;
  const latLong = await new Promise<google.maps.LatLngLiteral>(
    (resolve, reject) => {
      googleMapsGeocodingService.geocode({ address }, (results, status) => {
        if (status === 'OK' && results) {
          const latLongResult = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          resolve(latLongResult);
        } else {
          reject(status);
        }
      });
    }
  );
  return latLong;
};

export const getGoogleMapsUrl = (query: string) => {
  const url = `https://www.google.com/maps/search/?api=1&query=${query}`;
  return encodeURI(url);
};

export const getGooglePlaceFromLocationName = async (
  map: google.maps.Map,
  name: string | null
) => {
  if (!isGoogleMapsPlacesLibraryLoaded()) {
    return null;
  }

  const placesService = new google.maps.places.PlacesService(map);

  const placesRequest = {
    query: `${name}`,
    fields: ['place_id', 'name'],
  };

  const place = await new Promise<google.maps.places.PlaceResult>(
    (resolve, reject) => {
      placesService.findPlaceFromQuery(placesRequest, (results, status) => {
        if (status === 'OK' && results) {
          resolve(results[0]);
        } else {
          reject(status);
        }
      });
    }
  );

  return place;
};
