import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { UseQueryResult } from '@tanstack/react-query';

type AttachmentProps = {
  attachment: UseQueryResult<{ attachmentId: string; url: string }>;
  onRemove: (attachmentId: string) => void;
};

export function Attachment(props: AttachmentProps) {
  const { attachment, onRemove } = props;

  if (attachment.isLoading)
    return (
      <div className="grid aspect-photo place-items-center rounded-md bg-gray-1">
        <LoadingOutlined className="text-5xl text-charcoal-1" />
      </div>
    );
  if (attachment.isError)
    return (
      <div className="grid aspect-photo place-items-center rounded-md text-tomato-6">
        Error
      </div>
    );

  return (
    <div className="relative aspect-photo rounded-md">
      <button
        type="button"
        className="z-8 absolute right-2 top-2 h-8 w-8 border-0 bg-space-10 p-0 text-sm text-white hover:border-0"
        onClick={() => onRemove(attachment.data.attachmentId)}
      >
        <CloseOutlined />
      </button>
      <img
        className="aspect-square rounded-md"
        src={attachment.data.url}
        alt="Attachment"
      />
    </div>
  );
}
