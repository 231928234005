import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePatient } from 'providers/OnboardedPatientProvider';
import { ZoomComponent } from './ZoomComponent';

const VideoVisitPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { patientAccount } = usePatient();
  const queryParams = new URLSearchParams(location.search);

  const meetingNumber = queryParams.get('meetingNumber') || '';
  const password = queryParams.get('password') || '';

  useEffect(() => {
    if (!meetingNumber || !password) {
      navigate('/');
    }
  }, [meetingNumber, password, navigate]);

  if (!patientAccount) return null;

  const zoomConfig = {
    accountId: patientAccount.account_id,
    meetingNumber,
    password,
    userName: patientAccount.first_name,
  };

  return <ZoomComponent zoomConfig={zoomConfig} />;
};
export default VideoVisitPage;
