/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { LoadingOutlined } from '@ant-design/icons';
import { log } from 'lib/log';

export function FullPageLoading() {
  return (
    <div
      className="grid min-h-screen items-center justify-center bg-ivory-2"
      onClick={() => {
        log.error('Clicked on the thing');
      }}
    >
      <LoadingOutlined className="text-5xl text-charcoal-4" />
    </div>
  );
}
