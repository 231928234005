import { cn } from 'lib/util';

type HeadingProps = {
  children: React.ReactNode;
  className?: string;
};

export function Heading(props: HeadingProps) {
  const { children, className } = props;

  return (
    <h1 className={cn('mb-4 font-serif text-3xl font-thin', className)}>
      {children}
    </h1>
  );
}

export function SubHeading(props: HeadingProps) {
  const { children, className } = props;

  return (
    <h3 className={cn('mb-4 font-serif text-lg font-thin', className)}>
      {children}
    </h3>
  );
}

export function InnerSubHeading(props: HeadingProps) {
  const { children, className } = props;

  return (
    <h3
      className={cn('text-xl font-semibold leading-6 text-zinc-800', className)}
    >
      {children}
    </h3>
  );
}

type ParagraphProps = {
  children: React.ReactNode;
  type?: 'body' | 'note';
  className?: string;
};

export function Paragraph(props: ParagraphProps) {
  const { children, className } = props;
  const type = props.type ?? 'body';

  return (
    <p className={cn('mb-4', type === 'note' && 'text-charcoal-8', className)}>
      {children}
    </p>
  );
}

type LinkProps = {
  children: React.ReactNode;
  href: string;
  className?: string;
  dataTestId?: string;
};

export function Link(props: LinkProps) {
  const { children, href, className, dataTestId } = props;
  return (
    <a
      href={href}
      className={cn('text-lg text-denim-10 no-underline', className)}
      data-testid={dataTestId}
    >
      {children}
    </a>
  );
}

export function DarkLink(props: LinkProps) {
  const { children, href, className, dataTestId } = props;
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={cn(
        'text-base font-normal leading-tight text-zinc-800 underline visited:text-zinc-800',
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </a>
  );
}

export function ButtonLink(props: LinkProps) {
  const { children, href, className, dataTestId } = props;
  return (
    <a
      href={href}
      className={cn(
        'h-16 rounded-full border border-space-10 bg-space-10 px-7 py-5 text-lg leading-none text-white no-underline hover:border-space-10 hover:text-white',
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </a>
  );
}

type IntakeKickerProps = {
  children: React.ReactNode;
};

export function IntakeKicker(props: IntakeKickerProps) {
  const { children } = props;
  return (
    <h2 className="mb-4 text-xs font-semibold uppercase tracking-wider text-charcoal-6 md:text-sm">
      {children}
    </h2>
  );
}
