import { Select, SelectProps } from 'antd';
import { useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

export type SearchInputProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  autoFocus?: boolean;
  onSearch: (value: string) => Promise<SelectProps['options']>;
};

export function SearchInput<TFieldValues extends FieldValues>(
  props: SearchInputProps<TFieldValues>
) {
  const { control, name, onSearch } = props;
  const autoFocus = props.autoFocus ?? false;
  const { field } = useController({ name, control });
  const [options, setOptions] = useState<SelectProps['options']>([]);

  const handleSearch = async (value: string) => {
    const data = await onSearch(value);
    setOptions(data);
  };

  return (
    <fieldset className="relative">
      <Select
        showSearch
        className="peer z-0 mb-4 w-full text-lg"
        defaultActiveFirstOption={false}
        suffixIcon={null}
        notFoundContent={null}
        filterOption={false}
        autoFocus={autoFocus}
        value={field.value}
        onBlur={field.onBlur}
        onChange={field.onChange}
        onSearch={handleSearch}
        options={options}
      />
    </fieldset>
  );
}
