import { AxiosPromise } from 'axios';
import { client } from 'constants/apiV2Clients';
import { Account, ApiResponse, Appointment } from '../../models';
import { API, flaskApiClient } from '../../constants/apiEndpoints';

export const getAccountDetailsService = (
  accountId: string
): AxiosPromise<ApiResponse<Account>> => {
  return flaskApiClient.get(API.account(accountId));
};

export const getAppointmentsDetailsService = async (
  accountId: string
): AxiosPromise<ApiResponse<Appointment>> => {
  const response = await flaskApiClient.get(API.appointments(accountId));

  return response;
};

export const getCareplanPatientTodosService = async (
  accountId: string,
  careplanId: string
) => {
  const response = await flaskApiClient.get(
    API.accountCareplanTodos(accountId, careplanId)
  );
  return response.data;
};

export const getVideoConsultationsService = async (
  accountId: string,
  expiresAtEnd?: string,
  expiresAtStart?: string,
  isCompleted?: boolean
) => {
  const response =
    await client.videoConsultationsApi.videoConsultationsAccountsMeetingDetailsRetrieve(
      accountId,
      expiresAtEnd,
      expiresAtStart,
      isCompleted
    );
  return response.data;
};

export const getCasesAndCarePlansService = async (accountId: string) => {
  const response = await client.casesApi.casesAccountsHealthWebCasesList(
    accountId
  );
  return response.data;
};

export const getQuestRequisitionService = async (
  accountId: string,
  questOrderId: string
) => {
  const response = await flaskApiClient.get(
    API.questRequisition(accountId, questOrderId)
  );
  return response.data;
};

export const syncPhoneNumberFromMfaService = async (patientId: string) => {
  const response =
    await client.patientsApi.patientsAuth0RegistrationSyncMfaPhoneCreate(
      patientId
    );
  return response.status;
};
