import { useEffect } from 'react';
import { env } from 'lib/env';
import i18n from 'i18n';

export function useOnboardingNavigation() {
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (env.VITE_ENVIRONMENT === 'development') return;

      // In modern browsers, all we need to do is preventDefault and a
      // confirmation dialog will be shown. We *cannot* set a custom message
      e.preventDefault();

      // Older browsers require us to set a custom message
      e.returnValue = i18n.t('Are you sure you want to leave?', {
        ns: 'onboarding',
      });
      return i18n.t('Are you sure you want to leave?', { ns: 'onboarding' });
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, []);
}
